import { compareArray } from "Utils/functions";
import style from "./style.module.css";
import { useEffect, useMemo, useRef } from "react";

const useChartOptions = (riskexp) => {
  const oldsect = useRef();

  const chartdata = useMemo(() => {
    return [riskexp.highRisk, riskexp.mediumRisk, riskexp.lowRisk];
  }, [riskexp]);

  useEffect(() => {
    if (!compareArray(chartdata, oldsect.current)) {
      oldsect.current = chartdata;
    }
  }, [chartdata]);
  const data = {
    labels: ["High risk", "Medium risk", "Low risk"],
    datasets: [
      {
        labels: ["High risk", "Medium risk", "Low risk"],
        data: chartdata,
        backgroundColor: ["#f26062", "#F0DD7D", "#84D39A"],
        borderWidth: 0,
        cutout: "55%",
        radius: "80%",
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      getpng: false,
      datalabels: {
        display: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return value > 3 && !context.active;
        },
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (value) => {
          return `${value}%`;
        },
      },
      tooltip: {
        enabled: false,
        xAlign: "center",
        yAlign: "bottom",
        external: (ctx) => {
          const tooltipEl = document.getElementById("tooltip-risk");
          const tooltipModel = ctx.tooltip;
          const position = ctx.chart.canvas.getBoundingClientRect();
          let riskLevel = "High";
          let riskClassname = style.hight;

          if (
            tooltipModel.dataPoints &&
            tooltipModel.dataPoints[0].label == "Low risk"
          ) {
            riskLevel = "Low";
            riskClassname = style.low;
          } else if (
            tooltipModel.dataPoints &&
            tooltipModel.dataPoints[0].label == "Medium risk"
          ) {
            riskLevel = "Medium";
            riskClassname = style.medium;
          }
          tooltipEl.querySelector(
            ".indicator"
          ).className = `indicator ${riskClassname} ${style.indicator}`;
          tooltipEl.querySelector(".risk").textContent = riskLevel;
          tooltipEl.querySelector(".value").textContent = `${
            (tooltipModel.dataPoints &&
              Object.values(riskexp)[tooltipModel.dataPoints[0].dataIndex].value) ||
            ""
          }${tooltipModel.dataPoints && tooltipModel.dataPoints[0].raw}%`;
          tooltipEl.style.opacity = 1;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.display = "none";
          } else tooltipEl.style.display = "block";
          tooltipEl.style.left =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            tooltipEl.clientWidth / 2 +
            "px";
          tooltipEl.style.top =
            position.top +
            window.pageYOffset +
            tooltipModel.caretY -
            tooltipEl.clientHeight +
            "px";
        },
      },
      legend: { display: false },
    },
  };
  return [data, options];
};

const usePDFChartOptions = (riskexp, onImageSectorExposure) => {
  const oldsect = useRef();

  const chartdata = useMemo(() => {
    return [riskexp.highRisk, riskexp.mediumRisk, riskexp.lowRisk];
  }, [riskexp]);

  useEffect(() => {
    if (!compareArray(chartdata, oldsect.current)) {
      oldsect.current = chartdata;
    }
  }, [chartdata]);
  const data = {
    labels: ["High risk", "Medium risk", "Low risk"],
    datasets: [
      {
        labels: ["High risk", "Medium risk", "Low risk"],
        data: chartdata,
        backgroundColor: ["#f26062", "#F0DD7D", "#84D39A"],
        borderWidth: 0,
        cutout: "55%",
        radius: "80%",
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    plugins: {
      getpng: {
        updated: !compareArray(chartdata, oldsect.current),
        saveImage: onImageSectorExposure,
      },
      datalabels: {
        display: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return value > 3 && !context.active;
        },
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (value) => {
          return `${value}%`;
        },
      },
      legend: { display: false },
    },
  };
  return [data, options];
};

export { usePDFChartOptions };
export default useChartOptions;
