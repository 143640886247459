import React from "react";
import style from "./style.module.css";
import Icon from "Components/Icon";

const Warning = ({ errors }) => {
  return (
    <div className={style.container}>
      {errors.map((e, i) => {
        return (
          e != "" && (
            <div className={style.error} key={i}>
              <Icon icon="warn" />
              {e}
            </div>
          )
        );
      })}
    </div>
  );
};

export default Warning;
