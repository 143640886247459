import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Icon from "Components/Icon";
import style from "./style.module.css";
import React from "react";
import classNames from "classnames";

const AccordionPrimary = ({
  children,
  title,
  className,
  expanded,
  onChange,
  contentClassName,
  summaryClassname,
}) => {
  return (
    <Accordion
      disableGutters
      className={classNames(style.accordion, className)}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        className={classNames(style.summary, summaryClassname)}
        expandIcon={<Icon icon="chevronDown" width={16} height={16} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classNames(style.content, contentClassName)}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionPrimary };
