import { Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import style from "./style.module.css";
import FormStep from "Components/FormStep";
import { useDispatch, useSelector } from "react-redux";
import { clearUnprbForm, setSelected } from "reducers/assesmentForm";
import { PrimaryButton } from "Components/StyledButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setUnprbRefreshFromNow } from "reducers/refreshData";

const Form = () => {
  const dispatch = useDispatch();
  const selection = useSelector((state) => state.assesmentForm);
  const { t } = useTranslation();
  const Steps = t("UNPRB.steps", { returnObjects: true });
  const refreshAt = useSelector((state) => state.refresh);
  function validateSelection() {
    return Steps.every((s, i) => {
      return selection[i]
        ? s.levels.length == Object.keys(selection[i]).length
        : false;
    });
  }

  useEffect(() => {
    if (refreshAt.unprbRefreshAt < Date.now()) {
      dispatch(clearUnprbForm());
    }
  }, []);

  function handleLinkClick(e) {
    if (!validateSelection()) e.preventDefault();
  }

  return (
    <Container className={style.container} maxWidth={"xl"}>
      <Container maxWidth="lg">
        <Typography variant="h3" className={style["form-title"]}>
          UNPRB self-assessment
        </Typography>
      </Container>
      <Container maxWidth="lg">
        <Stack className={style.steps}>
          {Steps.map((e, i) => {
            return (
              <FormStep
                key={i}
                {...e}
                index={i}
                onCardClick={(step, option, selected) => {
                  dispatch(setSelected({ step, option, selected }));
                  if (refreshAt.unprbRefreshAt < Date.now()) {
                    dispatch(setUnprbRefreshFromNow());
                  }
                }}
                selection={selection[i]}
              />
            );
          })}
        </Stack>
        <Stack maxWidth={"lg"} alignItems={"flex-end"} className={style.finish}>
          <Link
            to={"/UNPRB/overview"}
            className={style["finish-link"]}
            onClick={handleLinkClick}
          >
            <PrimaryButton disabled={!validateSelection()}>
              Finish assessment
            </PrimaryButton>
          </Link>
        </Stack>
      </Container>
    </Container>
  );
};

export default Form;
