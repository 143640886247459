import { createSlice } from "@reduxjs/toolkit";

const initialState = { resultData: [], userData: [] };

const transactionsdata = createSlice({
  name: "transactionsdata",
  initialState,
  reducers: {
    saveTransaction: (state, action) => {
      if (action.payload.index != undefined) {
        state.resultData[action.payload.index] = action.payload.resultdata;
        state.userData[action.payload.index] = action.payload.userData;
      } else {
        state.resultData.push(action.payload.resultdata);
        state.userData.push(action.payload.userData);
      }
    },
    deleteTransaction: (state, action) => {
      state.resultData.splice(action.payload, 1);
      state.userData.splice(action.payload, 1);
    },
    clearTransactions: () => {
      return initialState;
    },
  },
});

export default transactionsdata.reducer;
export const { saveTransaction, deleteTransaction, clearTransactions } =
  transactionsdata.actions;
