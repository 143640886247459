import CardRisk from "Components/CardRisk";
import React from "react";
import style from "./style.module.css";
import { Doughnut } from "react-chartjs-2";
import useChartOptions, { usePDFChartOptions } from "./chartOptions";
import { useSelector } from "react-redux";
import classNames from "classnames";
import useTranslate from "Hooks/translate";
const RiskExposure = ({ onImageSectorRisk }) => {
  const riskExposure = useSelector((state) => state.portfoliodata.riskExposure);
  const [data, options] = useChartOptions(riskExposure);
  const [pdfchartdata, pdfchartoptions] = usePDFChartOptions(
    riskExposure,
    onImageSectorRisk
  );
  const textdata = useTranslate("RiskAssessement.portfolio.overview.riskExposure");

  return (
    <div className={style.container}>
      <div id="tooltip-risk" className={style.tooltip}>
        <div className={style.desc}>
          <span className={classNames(style["risk-indic"], "risk-indic")}>
            <span className={classNames(style.indicator, "indicator")}></span>
            <span className={classNames(style.risk, "risk")}></span>
          </span>
          <span className={classNames("value", style.value)}></span>
        </div>
      </div>
      <CardRisk
        title={textdata.title}
        desc={textdata.desc}
        className={style["risk-exposure"]}
      >
        <div style={{ height: "305px" }}>
          {data && <Doughnut data={data} options={options} />}
        </div>
      </CardRisk>
      <div
        style={{
          width: "250px",
          position: "absolute",
          zIndex: 999,
          background: "#fff",
          top: "-9999px",
        }}
      >
        {data && <Doughnut data={pdfchartdata} options={pdfchartoptions} />}
      </div>
    </div>
  );
};

export default RiskExposure;
