import Empty from "Components/Empty";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.css";
import TransactionsTable from "./TransactionsTable";
import { Container, Stack } from "@mui/material";
import TransactionDetails from "./TransactionDetails";
import { deleteTransaction } from "reducers/transactionsData";
import ConfirmDelete from "./ConfirmDelete";
import useTranslate from "Hooks/translate";

const Overview = ({ onEdit, expanded, setExpanded }) => {
  const transactionsdata = useSelector((state) => state.transactionsdata);

  const [deleteVisible, setdeleteVisible] = useState(false);
  const [elementTodel, setElemTodel] = useState();
  const dispatch = useDispatch();
  const textdata = useTranslate("RiskAssessement.transactions.overview");
  const ref = useRef(null);

  if (transactionsdata.resultData.length == 0)
    return <Empty message={textdata.empty} />;

  function handleExpand(v) {
    setExpanded(v);
  }

  function handleDelete(i) {
    setElemTodel(i);
    setdeleteVisible(true);
  }

  return (
    <>
      <ConfirmDelete
        visible={deleteVisible}
        onCancel={() => setdeleteVisible(false)}
        onConfirm={() => {
          setExpanded(expanded > 0 ? expanded - 1 : 0);
          dispatch(deleteTransaction(elementTodel));
          setdeleteVisible(false);
        }}
      />
      <Container maxWidth={false} className={style["table-container"]} ref={ref}>
        <TransactionsTable
          data={transactionsdata.resultData.map((e) => e.overview)}
          onExpand={handleExpand}
          onDelete={handleDelete}
          onEdit={onEdit}
        />
        <Stack alignItems={"end"}>
          <p className={style.note}>{textdata.aware}</p>
        </Stack>
      </Container>
      <TransactionDetails
        resultData={transactionsdata.resultData[expanded]}
        userData={transactionsdata.userData[expanded]}
      />
    </>
  );
};

export default Overview;
