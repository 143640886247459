import { Stack, Typography } from "@mui/material";
import style from "./style.module.css";
import React from "react";
const Card = ({ title, content, className }) => {
  return (
    <Stack className={[style.container, className]} gap={"10px"}>
      <Typography variant="h3">{title}</Typography>
      <hr />
      <Typography>{content}</Typography>
    </Stack>
  );
};

export default Card;
