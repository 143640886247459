import React, { useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import Icon from "Components/Icon";
import { Popover, Typography } from "@mui/material";

const levels = {
  High: 2,
  Medium: 1,
  Low: 0,
};

const iconMap = {
  "occupational health and safety": "occupational",
  "hazardous materials": "hazard",
  "water use and wastewater": "water",
  "air pollution": "airPollution",
  "impacts to community": "impact",
  "biodiversity and habitat": "biodiversity",
  "labor standards": "labor",
  "child and forced labor": "child",
  "exposure to disease / disease prevention": "desease",
  "animal welfare": "animal",
  "energy use": "energy",
  "waste management": "waste",
};

const ItemRisk = ({ title, level, keyRisks }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [Popovercontent, setPopovercontent] = useState(null);
  const handlePopoverOpen = (event, content) => {
    setPopovercontent(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      <h3 className={style.subtitle}>
        <span>Risk Level: &nbsp;</span>
        {level}
      </h3>
      <div className={style["risk-level"]}>
        {Array.from({ length: 3 }, (e, i) => (
          <span
            className={classNames(style.level, {
              [style[level]]: i <= levels[level],
            })}
            key={i}
          ></span>
        ))}
      </div>
      {keyRisks && (
        <>
          <h3 className={style.subtitle}>
            <span>Main Risks:</span>
          </h3>
          <div className={style.risks}>
            <div className={style["main-risks"]}>
              {keyRisks.map((e, i) => (
                <div key={i}>
                  <div
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(ev) => handlePopoverOpen(ev, e.type)}
                    onMouseLeave={handlePopoverClose}
                  >
                    <Icon icon={iconMap[e.type.toLowerCase()] || "default"} />
                  </div>
                  <Popover
                    id="mouse-over-popover"
                    PaperProps={{ elevation: 1 }}
                    sx={{
                      pointerEvents: "none",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={open}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>{Popovercontent}</Typography>
                  </Popover>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ItemRisk;
