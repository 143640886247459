import { ThemeProvider, createTheme } from "@mui/material";
import React, { createContext, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "reducers/theme";

export const ColorModeContext = createContext({
  setColorMode: () => {},
  colorMode: "light",
});

const MainThemeProvider = ({ children }) => {
  const themeValue = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const colorMode = useMemo(
    () => ({
      setColorMode: (cm) => {
        dispatch(setTheme(cm));
      },
      colorMode: themeValue,
    }),
    [themeValue]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeValue,
          darkBlue: {
            main: "#192E54",
          },
          lightBlue: {
            main: "#B1CADA",
          },
        },
        breakpoints: {
          values: {
            lg: 1180 + 32,
            xl: 1356 + 32,
          },
        },
        typography: {
          allVariants: {
            fontFamily: "Inter",
          },
        },
      }),
    [themeValue]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default MainThemeProvider;
