import React from "react";
import { Text, View } from "@react-pdf/renderer";
import moment from "moment";

const PDFFooter = () => {
  return (
    <View
      style={{
        height: "35pt",
        justifyContent: "center",
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        paddingHorizontal: "36pt",
        paddingBottom: "5pt",
        zIndex: 999,
      }}
      fixed
    >
      <View
        style={{
          width: "523pt",
          alignSelf: "center",
          borderTop: "1pt solid #192E54",
          paddingTop: "4pt",
        }}
      >
        <Text
          style={{
            fontSize: "5pt",
            fontStyle: "italic",
            fontFamily: "Inter",
            color: "#192E54",
            opacity: 0.7,
            marginBottom: "3pt",
          }}
        >
          Whilst FMO has taken reasonable care and diligence in developing this ESG
          tool, it should not be considered as exhaustive or as any form of advice.
          It is designed to be informative, and the user will have to make its own
          independent judgment as whether to rely thereon. Accordingly, FMO does not
          accept any liability for the use of this ESG tool and subsequent actions
          based thereon. By using this ESG tool, the user agrees to these terms.
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            fontStyle: "italic",
            fontFamily: "Inter",
            fontSize: "6pt",
            alignSelf: "stretch",
            color: "#192E54",
          }}
        >
          <Text>
            Report generated on:&nbsp;
            {`${moment().format("D MMMM YYYY")}`}
          </Text>
          <Text render={({ pageNumber }) => `Page ${pageNumber}`} />
        </View>
      </View>
    </View>
  );
};

export default PDFFooter;
