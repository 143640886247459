import First from "./First";
import Fourth from "./Fourth";
import Second from "./Second";
import Third from "./Third";
import React, { useMemo, useState } from "react";
import style from "./style.module.css";
import useTranslate from "Hooks/translate";
import { ReactSVG } from "react-svg";

const AccordionContents = ({ detail, onImageLoaded }) => {
  const [expanded, setExpanded] = useState(null);
  const handleChange = (ex) => () => {
    setExpanded((prev) => (ex == prev ? null : ex));
  };

  const El = useMemo(() => {
    function colorRect(svg, det) {
      const svgdoc = svg?.getElementsByClassName("rect");
      const svgdoctext = svg?.getElementsByClassName("text");
      const levelMap = { High: 2, Medium: 1, Low: 0 };
      const leveragelevelMap = { High: 1, Low: 0 };
      const levels = [
        [0, 3],
        [1, 4],
        [2, 5],
      ];
      const socialLevel = levelMap[det.socialRiskLevel];
      const levlerageLevel = leveragelevelMap[det.leverageLevel];
      const level = levels[socialLevel][levlerageLevel];
      for (let i = 0; i < svgdoc.length; i++) {
        svgdoc[i].style.fill = "#F4F5F6";
      }
      svgdoc[level].style.fill = "#192e54";
      for (let i = 0; i < svgdoctext.length; i++) {
        svgdoctext[i].style.fill = "#8A8E94";
      }
      svgdoctext[level].style.fill = "#fff";
    }
    return (
      <>
        <ReactSVG
          src="/Images/flow.svg"
          afterInjection={(svg) => {
            colorRect(svg, detail.suggestedESRiskManagementApproach);
          }}
          beforeInjection={(svg) => {
            svg.setAttribute("style", "width: 100%;pointer-events:none;");
          }}
        />
        <ReactSVG
          src="/Images/flow_pdf.svg"
          afterInjection={(svg) => {
            colorRect(svg, detail.suggestedESRiskManagementApproach);
            onImageLoaded(svg.cloneNode(true));
          }}
          beforeInjection={(svg) => {
            svg.setAttribute("style", "width: 100%;pointer-events:none;");
          }}
          style={{
            opacity: 0,
            pointerEvent: "none",
            position: "absolute",
            zIndex: -20,
          }}
        />
      </>
    );
  }, [detail]);
  const textdata = useTranslate("RiskAssessement.transactions.accordion");

  return (
    <>
      <h3 className={style.title}>{textdata.title}</h3>
      <First
        expanded={expanded == 0}
        onChange={handleChange(0)}
        keyRisks={detail.ESGRiskProfile.keyRisks}
        otherRisks={detail.ESGRiskProfile.otherRisks}
      />
      <Second
        expanded={expanded == 1}
        onChange={handleChange(1)}
        detail={detail.suggestedESRiskManagementApproach}
        Img={El}
      />
      <Third
        expanded={expanded == 2}
        onChange={handleChange(2)}
        detail={detail.corporateGovernanceRiskManagementGuidance}
      />
      <Fourth
        expanded={expanded == 3}
        onChange={handleChange(3)}
        library={detail.library}
      />
    </>
  );
};

export default AccordionContents;
