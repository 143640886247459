import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const currentPortfolio = createSlice({
  name: "currentPortfolio",
  initialState,
  reducers: {
    editPortfolio: (state, action) => {
      return action.payload;
    },
    clearCurrentportfolio: () => {
      return initialState;
    },
  },
});

export default currentPortfolio.reducer;
export const { editPortfolio, clearCurrentportfolio } = currentPortfolio.actions;
