import {
  Document,
  Page,
  Image,
  View,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import React from "react";

import interitalic from "Assets/fonts/Inter-Italic.otf";
import interbold from "Assets/fonts/Inter-Bold.otf";
import interreg from "Assets/fonts/Inter-Regular.ttf";
import intersembold from "Assets/fonts/Inter-SemiBold.ttf";
import PDFFooter from "Components/PDFFooter";
import PDFHeader from "Components/PDFHeader";
import { formatNumber } from "Utils/functions";

Font.register({
  family: "InterBold",
  format: "truetype",
  src: interbold,
  fontWeight: "700",
});

Font.register({
  family: "Inter",
  format: "truetype",
  fontStyle: "italic",
  src: interitalic,
});

Font.register({
  family: "Inter",
  format: "truetype",
  src: interreg,
  fontWeight: "400",
});

Font.register({
  family: "InterSemBold",
  format: "truetype",
  src: intersembold,
  fontWeight: "600",
});

const iconMap = {
  "occupational health and safety": "occupational",
  "hazardous materials": "hazard",
  "water use and wastewater": "water",
  "air pollution": "airPollution",
  "impacts to community": "impact",
  "biodiversity and habitat": "biodiversity",
  "labor standards": "labor",
  "child and forced labor": "child",
  "energy use": "energy",
  "waste management": "waste",
  "exposure to disease / disease prevention": "biodiversity",
  "animal welfare": "welfare",
};

const icons = {
  impact: "/Images/IC_ImpactsToCommunity.png",
  hazard: "/Images/IC_HazardousMaterial.png",
  labor: "/Images/IC_LaborStandard.png",
  biodiversity: "/Images/IC_BiodiversityAndHabitate.png",
  child: "/Images/IC_ChildAndForcedLabor.png",
  airPollution: "/Images/IC_AirPollution.png",
  water: "/Images/IC_WaterUseAndWasteWater.png",
  occupational: "/Images/IC_OccupationalHealthAndSafety.png",
  default: "/Images/default.png",
  energy: "/Images/IC_EnergyConsumption.png",
  waste: "/Images/IC_WasteManagement.png",
  welfare: "/Images/IC_AnimalWelfare.png",
};

const Card = ({ name, value, riskLevel, keyRisks, curr }) => {
  const style = StyleSheet.create({
    container: {
      backgroundColor: "#fff",
      color: "#192E54",
      fontFamily: "Inter",
      flexDirection: "row",
      alignItems: "flex-start",
      paddingBottom: "6pt",
      borderBottom: "1pt solid #B1CADA",
    },
    name: {
      fontSize: "10pt",
      // color: "#192E54",
      flexBasis: "19.5%",
      flexShrink: 0,
      marginTop: "1pt",
      opacity: 0.7,
    },
    "risk-level": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      width: "100%",
      marginTop: "5pt",
      marginBottom: "4pt",
    },
    currency: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      width: "100%",
    },
    "level-indicator": {
      marginTop: "2.5pt",
      width: "6pt",
      height: "6pt",
      borderRadius: "6pt",
    },
    level: { fontSize: "8pt", opacity: 0.7 },
    "main-risks": {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "4pt",
      flexBasis: "67%",
      flexGrow: 0,
      paddingLeft: "10pt",
    },
    risk: {
      flexBasis: "64pt",
      flexShrink: 0,
      flexGrow: 0,
      alignItems: "center",
      gap: "3pt",
    },
    "risk-name": {
      fontSize: "8pt",
      textAlign: "center",
      width: "64pt",
      height: "30pt",
      overflow: "hidden",
      opacity: 0.7,
    },
    "risk-image": { width: "20pt", height: "20pt" },
    High: {
      backgroundColor: "#f01f1f",
    },
    Medium: {
      backgroundColor: "#f0dd7d",
    },
    Low: {
      backgroundColor: "#84D39A",
    },
  });

  return (
    <View style={style.container} wrap={false}>
      <Text style={style.name}>{name}</Text>

      <View
        style={{
          alignItems: "flex-start",
          gap: "5pt",
          flexShrink: 0,
          flexBasis: "13.5%",
          paddingLeft: "10pt",
        }}
      >
        <View style={style["risk-level"]}>
          <View style={[style["level-indicator"], style[riskLevel]]}></View>
          <Text style={[style.level]}>{riskLevel}</Text>
        </View>
        <View style={style.currency}>
          <Text style={[style.level]}>{curr == "usd" ? "$" : "€"}</Text>
          <Text style={[style.level]}>{formatNumber(value.toString())}</Text>
        </View>
      </View>

      <View style={style["main-risks"]}>
        {keyRisks.map((e, i) => {
          return (
            <View key={i} style={style.risk}>
              <Image
                src={icons[iconMap[e.type.toLowerCase()]] || "default"}
                style={style["risk-image"]}
              />
              <Text style={style["risk-name"]}>{e.type}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const MyDoc = ({ imageSectorExposure, imageSectorRisk, portfoliodata, curr }) => {
  const style = StyleSheet.create({
    chartWrapper: {
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
    },
    imageContainer: {
      marginBottom: "10pt",
    },
    subtitle: {
      color: "#192e54",
      fontFamily: "Inter",
      fontSize: "10pt",
      marginBottom: "5pt",
      opacity: "0.7",
    },
    image: { width: "250pt" },
    "table-header": {
      flexDirection: "row",
      fontFamily: "InterSemBold",
      fontSize: "10pt",
      color: "#192E54",
    },
    title: {
      fontSize: "10pt",
      fontFamily: "InterSemBold",
      color: "#192E54",
      alignSelf: "center",
      marginBottom: "10pt",
    },
    legend: {
      fontSize: "7pt",
      fontFamily: "Inter",
      color: "#192e54",
      opacity: 0.7,
    },
  });
  return (
    <Document>
      <Page style={{ padding: "60pt 30pt 36pt 60pt" }}>
        <PDFHeader />
        <Text
          style={{
            fontSize: "16pt",
            fontFamily: "InterBold",
            color: "#192E54",
            marginBottom: "10pt",
          }}
        >
          Portfolio Assessment - Overview
        </Text>
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "3pt 10pt",
          }}
        >
          <View style={style.imageContainer}>
            <Text style={style.title}>Sector exposure</Text>
            <Image source={imageSectorExposure} style={style.image} />
          </View>

          <View style={style.imageContainer} wrap={false}>
            <Text style={style.title}>
              Environmental & social (E&S) risk exposure
            </Text>
            <View style={{ alignSelf: "center", gap: "20pt" }}>
              <View style={{ alignSelf: "center" }}>
                <Image
                  source={imageSectorRisk}
                  style={[style.image, { width: "165pt", marginTop: "-10pt" }]}
                />
                <View style={{ alignSelf: "center", gap: "8pt" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8pt",
                    }}
                  >
                    <View
                      style={{
                        width: "8pt",
                        height: "8pt",
                        backgroundColor: "#84D39A",
                      }}
                    ></View>
                    <Text style={style.legend}>
                      Low - {portfoliodata.riskExposure.lowRisk}%
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8pt",
                    }}
                  >
                    <View
                      style={{
                        width: "8pt",
                        height: "8pt",
                        backgroundColor: "#F0DD7D",
                      }}
                    ></View>
                    <Text style={style.legend}>
                      Medium - {portfoliodata.riskExposure.mediumRisk}%
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8pt",
                    }}
                  >
                    <View
                      style={{
                        width: "8pt",
                        height: "8pt",
                        backgroundColor: "#f26062",
                      }}
                    ></View>
                    <Text style={style.legend}>
                      High - {portfoliodata.riskExposure.highRisk}%
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text
            style={{
              fontSize: "12pt",
              fontFamily: "InterBold",
              color: "#192E54",
              marginBottom: "4pt",
            }}
          >
            Detailed portfolio E&S risk exposure
          </Text>
          <View
            style={{
              width: "100%",
              height: "1pt",
              backgroundColor: "#000000",
              marginBottom: "2pt",
            }}
          ></View>
          <View
            style={{
              gap: "10pt",
            }}
          >
            <View style={style["table-header"]}>
              <Text style={{ flexBasis: "91pt", flexShrink: 0 }}>
                Sector/sub sector
              </Text>
              <Text style={{ margin: "0pt 20pt" }}>Risk level</Text>
              <Text>Main risks *</Text>
            </View>
            {portfoliodata.riskiestSectors.map((e, i) => {
              return <Card curr={curr} key={i} {...e} />;
            })}
          </View>
          <Text
            style={{
              fontSize: "8pt",
              color: "#192E54",
              fontFamily: "Inter",
              fontStyle: "italic",
              textAlign: "right",
              marginTop: "3pt",
              opacity: 0.7,
            }}
          >
            *For more information on main risks, please download the sector
            guidelines
          </Text>
        </View>
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default MyDoc;
