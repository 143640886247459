import React from "react";
import style from "./style.module.css";
import classNames from "classnames";

const sx = classNames.bind({
  ...style,
});

const Empty = ({ message, className }) => {
  return <div className={sx(style.container, className)}>{message}</div>;
};

export default Empty;
