import api from "Api";
import { useState } from "react";
import useTransactionDataTransform from "./transactionDataTransform";
import { GENERATE_TRANSACTION_RESULT } from "Api/endpoints";

const useTransactionResult = (formfields) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const reqdata = useTransactionDataTransform(formfields);

  const request = () => {
    setLoading(true);
    api
      .post(GENERATE_TRANSACTION_RESULT, reqdata)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };
  return [request, loading, data, error];
};

export default useTransactionResult;
