import { Stack, Typography } from "@mui/material";
import style from "./style.module.css";
import React from "react";
import { PrimaryButton } from "Components/StyledButton";
import { Link } from "react-router-dom";

const Portfoliocard = ({ title, points, desc, to }) => {
  return (
    <Stack className={style.container}>
      <Typography className={style.title}>{title}</Typography>
      <Typography className={style.contents}>{desc}</Typography>
      <ul className={style.points}>
        {points.map((e, i) => {
          return <li key={i}>{e}</li>;
        })}
      </ul>
      <Link className={style.link} to={to}>
        <PrimaryButton>Start</PrimaryButton>
      </Link>
    </Stack>
  );
};

export default Portfoliocard;
