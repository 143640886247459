import { compareArray, formatNumber } from "Utils/functions";
import style from "./style.module.css";
import { useEffect, useMemo, useRef } from "react";

const useChartOptions = (sectExp) => {
  const oldsect = useRef();

  const chartdata = useMemo(() => {
    return sectExp?.map((e) => e.percent);
  }, [sectExp]);

  useEffect(() => {
    if (!compareArray(chartdata, oldsect.current)) {
      oldsect.current = chartdata;
    }
  }, [chartdata]);

  const data = {
    labels: sectExp.map((e) => {
      const label = e.name;
      if (label.includes("Water supply;")) {
        return "Water supply; sewage, waste management...";
      } else return label;
    }),
    datasets: [
      {
        radius: "90%",
        label: "Sector exposure",
        offset: -2,
        data: chartdata,
        borderWidth: 0,
        cutout: "55%",
        backgroundColor: [
          "#111F71",
          "#1C2F89",
          "#2D45AA",
          "#4160CB",
          "#5A7EED",
          "#82A1F4",
          "#9CB8F9",
          "#BED2FD",
          "#DEE9FE",
        ],
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,

    plugins: {
      getpng: false,
      datalabels: {
        display: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return value > 3 && !context.active;
        },
        color: "#fff",
        font: {
          weight: "bold",
        },
        formatter: (value) => value + "%",
      },
      tooltip: {
        enabled: false,
        yAlign: "bottom",
        xAlign: "center",
        external: (ctx) => {
          const tooltipModel = ctx.tooltip;
          if (tooltipModel.dataPoints) {
            const tooltipEl = document.getElementById("tooltip-sector");

            const position = ctx.chart.canvas.getBoundingClientRect();
            tooltipEl.querySelector(".title").textContent =
              tooltipModel.dataPoints[0].label;

            let riskClassname = style.hight;

            const riskLevel =
              sectExp[tooltipModel.dataPoints[0].dataIndex].riskLevel;

            if (riskLevel == "Low") {
              riskClassname = style.low;
            } else if (riskLevel == "Medium") {
              riskClassname = style.medium;
            }

            if (tooltipModel.dataPoints[0].label == "Other sectors") {
              tooltipEl.querySelector(".indicator").style.display = "none";
            } else {
              tooltipEl.querySelector(".indicator").style.display = "inline-block";
            }
            tooltipEl.querySelector(
              ".indicator"
            ).className = `indicator ${riskClassname} ${style.indicator}`;

            tooltipEl.querySelector(".risk").textContent = riskLevel;
            tooltipEl.querySelector(".value").textContent = `${
              formatNumber(
                sectExp[tooltipModel.dataPoints[0].dataIndex].value?.toString() || ""
              ) || ""
            } (${tooltipModel.dataPoints[0].raw}%)`;
            tooltipEl.style.opacity = 1;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.display = "none";
            } else tooltipEl.style.display = "block";
            tooltipEl.style.left =
              position.left +
              window.pageXOffset +
              tooltipModel.caretX -
              tooltipEl.clientWidth / 2 +
              "px";
            tooltipEl.style.top =
              position.top +
              window.pageYOffset +
              tooltipModel.caretY -
              tooltipEl.clientHeight +
              "px";
          }
        },
      },
      legend: {
        onClick: null,
        display: true,
        position: "left",
        labels: {
          boxWidth: 15,
          font: {
            size: 11,
            weight: 400,
          },
          color: "#192E54",
          filter: (li, cd, f) => {
            return cd.datasets[0].data[li.index];
          },
        },
      },
    },
  };

  return [data, options];
};

const usePDFChartOptions = (sectExp, onImageSectorExposure) => {
  const oldsect = useRef();

  const chartdata = useMemo(() => {
    return sectExp?.map((e) => e.percent);
  }, [sectExp]);

  useEffect(() => {
    if (!compareArray(chartdata, oldsect.current)) {
      oldsect.current = chartdata;
    }
  }, [chartdata]);

  const data = {
    labels: sectExp.map((e) => e.name),
    datasets: [
      {
        radius: "90%",
        label: "Sector exposure",
        offset: -2,
        data: chartdata,
        borderWidth: 0,
        cutout: "55%",
        backgroundColor: [
          "#111F71",
          "#1C2F89",
          "#2D45AA",
          "#4160CB",
          "#5A7EED",
          "#82A1F4",
          "#9CB8F9",
          "#BED2FD",
          "#DEE9FE",
        ],
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    plugins: {
      getpng: {
        updated: !compareArray(chartdata, oldsect.current),
        saveImage: onImageSectorExposure,
      },
      datalabels: {
        display: function (context) {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return value > 2;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 14,
        },
        formatter: (value) => value + "%",
      },
      tooltip: false,
      legend: {
        maxWidth: 100,
        fullSize: false,
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          boxWidth: 15,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            return datasets[0].data.map((data, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${chart.data.labels[i]} - ${formatNumber(
                  sectExp[i].value.toString()
                )} (${data}%)${Array(50).join(" ")}`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                fontColor: "#192E54",
                hidden: !chart.getDataVisibility(i),
                index: i,
              };
            });
          },
          font: {
            size: 13,
            weight: 400,
          },
          filter: (li, cd, f) => {
            return cd.datasets[0].data[li.index];
          },
        },
      },
    },
  };

  return [data, options];
};

export { usePDFChartOptions };
export default useChartOptions;
