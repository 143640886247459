import React from "react";
import style from "./style.module.css";
import Icon from "Components/Icon";
import classNames from "classnames";
import { Modal as MuiModal } from "@mui/material";
const Modal = ({ title, children, visible, onClose, subtitle, className }) => {
  return (
    <MuiModal
      className={classNames(style["main-wrapper"], {
        [style.visible]: visible,
        [style.invisible]: !visible,
      })}
      onClose={onClose}
      open={visible}
    >
      <div className={classNames(style.container, className)}>
        <div className={style.header}>
          <h3>{title}</h3>
          <Icon icon="quit" onClick={onClose} className={style.close} />
        </div>
        {subtitle && <h3 className={style.subtitle}>{subtitle}</h3>}
        <div>{children}</div>
      </div>
    </MuiModal>
  );
};

export default Modal;
