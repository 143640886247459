import Modal from "Components/Modal";
import React, { useEffect, useState } from "react";
import Sectors from "./Sectors";
import { CircularProgress, Container, Typography } from "@mui/material";
import style from "./style.module.css";
import useTranslate from "Hooks/translate";
import ExposureNonPerm from "./ExposureNonPerm";
import ExposurePerm from "./ExposurePerm";
import { useDispatch, useSelector } from "react-redux";
import { clearTempData } from "reducers/tempportfoliodata";
import { savePortfolio } from "reducers/portfoliodata";
import { editPortfolio } from "reducers/currendPortfolio";
import { transformFormdata } from "Utils/functions";
import { setPortfolioRefreshFromNow } from "reducers/refreshData";

const NewPortfolio = ({ visible, onClose, editMode, formfielddata, loading }) => {
  const [activeStep, setActiveStep] = useState(0);
  const stepsData = useTranslate("RiskAssessement.portfolio.steps");
  const dispatch = useDispatch();
  const formdata = useSelector((state) => state.tempportfoliodata);
  const textdata = useTranslate("RiskAssessement.portfolio.newPortfolio");

  const curr = useSelector((state) => state.currency);

  const stepsBody = [
    loading ? (
      <CircularProgress />
    ) : (
      <Sectors
        key={0}
        nexStep={handleNext}
        formdata={formdata.exp}
        formfielddata={formfielddata}
        visible={visible}
      />
    ),
    <ExposureNonPerm
      key={1}
      nexStep={handleNext}
      prevStep={handlePrev}
      formdata={formdata.expNperm}
    />,
    loading ? (
      <CircularProgress />
    ) : (
      <ExposurePerm
        key={2}
        onCreatePortfolio={createPortfolio}
        prevStep={handlePrev}
        formdata={formdata.expPerm}
        editMode={editMode}
        dummydatasect={transformFormdata(formfielddata.sectorList)}
      />
    ),
  ];

  useEffect(() => {
    setActiveStep(0);
  }, [visible]);

  function createPortfolio(portfolioResultData) {
    dispatch(savePortfolio(portfolioResultData));
    dispatch(editPortfolio(formdata));
    dispatch(clearTempData(formfielddata));
    dispatch(setPortfolioRefreshFromNow());
    onClose();
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handlePrev() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Modal
      visible={visible}
      title={textdata.title}
      onClose={onClose}
      className={style.modal}
    >
      <Typography className={style.title}>{`Step (${activeStep + 1}/${
        stepsData.length
      }) - ${stepsData[activeStep].title}`}</Typography>
      <Typography className={style.desc}>
        {stepsData[activeStep].desc &&
          stepsData[activeStep].desc.replace("USD", curr.toUpperCase())}{" "}
        {stepsData[activeStep].link && (
          <a
            className={style.link}
            href="https://www.fmo.nl/policies-and-position-statements"
            target="_blank"
            rel="noreferrer"
          >
            {stepsData[activeStep].link}
          </a>
        )}
      </Typography>
      <Typography className={style.quest}>{stepsData[activeStep].quest}</Typography>
      <Container maxWidth={false} className={style.container}>
        {stepsBody[activeStep]}
      </Container>
    </Modal>
  );
};

export default NewPortfolio;
