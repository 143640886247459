import { t } from "i18next";
import { Stack, Typography } from "@mui/material";
import Icon from "Components/Icon";
import { BorderBotton } from "Components/StyledButton";
import { AccordionPrimary } from "Components/Accordion";
import style from "./style.module.css";
import React from "react";

const Third = ({ onChange, expanded }) => {
  const trans = t("EGM.accordion", { returnObjects: true })[2];

  return (
    <AccordionPrimary
      title={trans.title}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      <div className={style.container}>
        <Typography className={style.p0}>{trans.paragraphs[0]}</Typography>
        <Typography className={style.p1}>{trans.paragraphs[1]}</Typography>
        <img src="/Images/flowchart.svg" width={"100%"} />
        <Typography className={style.p2}>{trans.paragraphs[2]}</Typography>
        <Stack justifyContent={"space-between"} direction={"row"}>
          {trans.btns.map((e, i) => {
            return (
              <a key={i} className={style.links} href={e.href} download={e.name}>
                <BorderBotton
                  startIcon={
                    <Icon icon="download" color={"green"} width={16} height={16} />
                  }
                  className={style["border-buttons"]}
                >
                  <Typography>{e.label}</Typography>
                </BorderBotton>
              </a>
            );
          })}
        </Stack>
      </div>
    </AccordionPrimary>
  );
};

export default Third;
