import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keytransaction: {
    loansize: "",
    totalasset: "",
    company: "",
    sector: 0,
    subsector: 0,
    country: 0,
    confirm: false,
  },
  corporate: {},
};

const temptransactiondata = createSlice({
  name: "temptransactiondata",
  initialState,
  reducers: {
    setKeyTransaction: (state, action) => {
      return {
        ...state,
        keytransaction: { ...state.keytransaction, ...action.payload },
      };
    },
    setCorporate: (state, action) => {
      return { ...state, corporate: { ...state.corporate, ...action.payload } };
    },
    clearTempTrans: () => {
      return initialState;
    },
    setTempTransaction: (state, action) => {
      return action.payload;
    },
  },
});

export default temptransactiondata.reducer;
export const {
  setCorporate,
  setKeyTransaction,
  clearTempTrans,
  setTempTransaction,
} = temptransactiondata.actions;
