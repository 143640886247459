const allKeys = [
  ["committee", "processes", "remunerationPractices"],
  ["scope", "portfolioComposition", "context", "performanceMeasurement"],
  [
    "sMARTTargetsAndKPIs",
    "alignment",
    "baseline",
    "actionPlan",
    "implementation",
    "monitoring",
  ],
  ["publicDisclosure"],
];

const formattedfloatRegex = /^$|^(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/g;
const floatRegex = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?%$/;

export { allKeys, formattedfloatRegex, floatRegex };
