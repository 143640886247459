import { AccordionPrimary } from "Components/Accordion";
import React from "react";
import Contents from "./Contents";
import style from "./index.module.css";
import useTranslate from "Hooks/translate";

const First = ({ onChange, expanded, keyRisks, otherRisks }) => {
  const textdata = useTranslate("RiskAssessement.transactions.accordion.first");
  return (
    <AccordionPrimary
      title={textdata.title}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
      summaryClassname={style.summary}
    >
      <div className={style.container}>
        <Contents keyRisks={keyRisks} otherRisks={otherRisks} />
      </div>
    </AccordionPrimary>
  );
};

export default First;
