import React from "react";
import style from "./style.module.css";

const WhiteLogo = ({ showPowered }) => {
  return (
    <div className={style.container}>
      <img src="/Images/FMO_Logo_White.png" />
      <div>
        <img src="/Images/FI_Logo_White.png" />
        <div>{showPowered && <img src="/Images/Powered_Logo_White.png" />}</div>
      </div>
    </div>
  );
};

export default WhiteLogo;
