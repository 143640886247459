import React, { useEffect, useState } from "react";
import NewPortfolio from "./NewPortfolio";
import ControlHeader from "Components/ControlHeader";
import Overview from "./Overview";
import { useDispatch, useSelector } from "react-redux";
import { clearTempData, setTempData } from "reducers/tempportfoliodata";
import useTranslate from "Hooks/translate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CircularProgress } from "@mui/material";
import MyDoc from "./pdfDoc";
import { IconButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import style from "./style.module.css";
import { clearPortfolio } from "reducers/portfoliodata";
import { clearCurrentportfolio } from "reducers/currendPortfolio";
import useFormField from "Hooks/formFieldsData";

const Portfolio = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const portfoliodata = useSelector((state) => state.portfoliodata);
  const currentData = useSelector((state) => state.currentPortfolio);
  const [editMode, setEditMode] = useState(false);
  const [imageSectorExposure, setImageSectorExposure] = useState();
  const [imageSectorRisk, setImageSectorRisk] = useState();
  const curr = useSelector((state) => state.currency);
  const refreshAt = useSelector((state) => state.refresh);
  const [formfielddata, loading] = useFormField();

  useEffect(() => {
    if (refreshAt.portfolioRefreshAt < Date.now()) {
      dispatch(clearCurrentportfolio());
      dispatch(clearPortfolio());
    }
  }, []);

  const dispatch = useDispatch();
  const textdata = useTranslate("RiskAssessement.portfolio");
  return (
    <>
      <ControlHeader
        onNew={() => {
          setEditMode(false);
          setModalVisible(true);
          if (formfielddata) dispatch(clearTempData(formfielddata));
        }}
        newLabel={textdata.newLabel}
        download={Object.keys(portfoliodata) != 0}
        downloadElem={
          (imageSectorExposure,
          imageSectorRisk && (
            <PDFDownloadLink
              document={
                <MyDoc
                  {...{
                    imageSectorExposure,
                    imageSectorRisk,
                    textdata,
                    portfoliodata,
                    curr,
                  }}
                />
              }
              fileName="portfolio_assessement.pdf"
            >
              {({ blob, url, load, error }) =>
                load & imageSectorExposure & imageSectorRisk ? (
                  <CircularProgress className={style.progress} />
                ) : (
                  <IconButton>
                    <Icon icon={"download"} />
                  </IconButton>
                )
              }
            </PDFDownloadLink>
          ))
        }
        edit={Object.keys(portfoliodata) != 0}
        onEdit={() => {
          dispatch(setTempData(currentData));
          setEditMode(true);
          setModalVisible(true);
        }}
      />
      <Overview
        onImageSectorExposure={(img) => {
          setImageSectorExposure(img);
        }}
        onImageSectorRisk={(img) => {
          setImageSectorRisk(img);
        }}
      />
      <NewPortfolio
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        editMode={editMode}
        formfielddata={formfielddata}
        loading={loading}
      />
    </>
  );
};

export default Portfolio;
