import React from "react";
import Empty from "Components/Empty";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import style from "./style.module.css";
import SectorExposure from "./SectorExposure";
import RiskExposure from "./RiskExposure";
import DetailedPortfolio from "./DetailedPortfolio";
import Warning from "Components/Warning";
import useTranslate from "Hooks/translate";
import ForInsight from "./ForInsight";

const Overview = ({ onImageSectorExposure, onImageSectorRisk }) => {
  const portfoliodata = useSelector((state) => state.portfoliodata);
  const textdata = useTranslate("RiskAssessement.portfolio");

  if (Object.keys(portfoliodata).length == 0)
    return <Empty message={textdata.emptyLabel} />;

  return (
    <>
      {(!portfoliodata.step2Result.success ||
        !portfoliodata.step3Result.success) && (
        <div className={style["extend-width"]}>
          <Warning
            errors={[
              portfoliodata.step2Result.error,
              portfoliodata.step3Result.error,
            ]}
          />
        </div>
      )}
      <Container
        maxWidth={"xl"}
        className={[style.container, style["extend-width"]]}
      >
        <SectorExposure onImageSectorExposure={onImageSectorExposure} />
        <RiskExposure onImageSectorRisk={onImageSectorRisk} />
        <DetailedPortfolio />
        <ForInsight />
      </Container>
    </>
  );
};

export default Overview;
