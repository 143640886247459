import { AccordionPrimary } from "Components/Accordion";
import React, { useState } from "react";
import style from "./style.module.css";
import classNames from "classnames";
import useTranslate from "Hooks/translate";

const Contents = ({ keyRisks, otherRisks }) => {
  const [expanded, setExpanded] = useState(null);
  const textdata = useTranslate("RiskAssessement.transactions.accordion.first");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded && panel != expanded ? panel : null);
  };

  const exclusionSuggest = [
    "Child and Forced Labor",
    "Biodiversity and Habitat",
    "Hazardous Materials",
  ];
  const exclusionCheck = ["Biodiversity and Habitat", "Hazardous Materials"];

  return (
    <>
      <div>
        <h3 className={classNames(style["risk-title"], style["main-risk-title"])}>
          Main risks
        </h3>
        {keyRisks.map((e, i) => (
          <AccordionPrimary
            expanded={expanded == i}
            key={i}
            title={e.type}
            className={style["accordion-keyrisk"]}
            contentClassName={[style.contents, style["contents-container"]]}
            summaryClassname={[
              style["accordion-keyrisk-summary"],
              style["accordion-keyrisk-summary-main"],
            ]}
            onChange={handleChange(i)}
          >
            <div>
              <span className={style["list-title"]}>{textdata.checkif} ...</span>
              <ul>
                {e.checkIf.split("\n-").map((s, k) => (
                  <li
                    className={classNames(
                      {
                        [style.warn]: exclusionCheck.includes(e.type) && k == 0,
                      },
                      style["white-space"]
                    )}
                    key={k}
                  >
                    {s.indexOf("-") == 0 ? s.slice(1) : s}
                  </li>
                ))}
              </ul>
            </div>
            <span className={style.sep}></span>
            <div>
              <span className={style["list-title"]}>{textdata.suggestto} ...</span>
              <ul>
                {e.suggestTo.split("\n-").map((s, k) => (
                  <li
                    className={classNames(
                      {
                        [style.warn]: exclusionSuggest.includes(e.type) && k == 0,
                      },
                      style["white-space"]
                    )}
                    key={k}
                  >
                    {s.indexOf("-") == 0 ? s.slice(1) : s}
                  </li>
                ))}
              </ul>
            </div>
          </AccordionPrimary>
        ))}
      </div>
      <h3 className={classNames(style["risk-title"], style["other-risk-title"])}>
        Other potential risks
      </h3>
      <div>
        {otherRisks.map((e, i) => (
          <AccordionPrimary
            onChange={handleChange(i + keyRisks.length)}
            expanded={i + keyRisks.length == expanded}
            key={i}
            title={e.type}
            summaryClassname={[
              style["accordion-keyrisk-summary"],
              style["accordion-keyrisk-summary-others"],
            ]}
            className={style["accordion-keyrisk"]}
            contentClassName={[style.contents, style["contents-container"]]}
          >
            <div>
              <span className={style["list-title"]}>{textdata.checkif} ...</span>
              <ul>
                {e.checkIf.split("\n-").map((s, k) => (
                  <li
                    className={classNames(
                      {
                        [style.warn]: exclusionCheck.includes(e.type) && k == 0,
                      },
                      style["white-space"]
                    )}
                    key={k}
                  >
                    {s.indexOf("-") == 0 ? s.slice(1) : s}
                  </li>
                ))}
              </ul>
            </div>
            <span className={style.sep}></span>
            <div>
              <span className={style["list-title"]}>{textdata.suggestto} ...</span>
              <ul>
                {e.suggestTo.split("\n-").map((s, k) => (
                  <li
                    className={classNames(
                      {
                        [style.warn]: exclusionSuggest.includes(e.type) && k == 0,
                      },
                      style["white-space"]
                    )}
                    key={k}
                  >
                    {s.indexOf("-") == 0 ? s.slice(1) : s}
                  </li>
                ))}
              </ul>
            </div>
          </AccordionPrimary>
        ))}
      </div>
    </>
  );
};

export default Contents;
