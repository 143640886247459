import { Cell, CellContainer, Row } from "Components/CustomTable";
import React from "react";

const SingleRow = ({ data, className }) => {
  return (
    <Row className={className}>
      {data.map((e, i) => {
        return (
          <CellContainer key={i} span={e.span}>
            <Cell className={e.className}>{e.value}</Cell>
          </CellContainer>
        );
      })}
    </Row>
  );
};

export default SingleRow;
