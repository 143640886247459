import { StyleSheet, Text, View } from "@react-pdf/renderer";
import useTranslate from "Hooks/translate";
import React from "react";

const exclusionSuggest = ["Child and Forced Labor", "Biodiversity and Habitat"];
const exclusionCheck = ["Biodiversity and Habitat"];

const PDFGuidelines = ({ keyRisks, otherRisks, title }) => {
  const textdata = useTranslate("RiskAssessement.transactions.accordion.first");
  const style = StyleSheet.create({
    container: {
      color: "#192e54",
    },
    label: {
      fontWeight: "400",
      fontSize: "10pt",
      lineHeight: 1.2,
      opacity: 0.8,
    },
    content: {
      justifyContent: "space-between",
      gap: "3pt",
      marginBottom: "10pt",
      paddingHorizontal: "8pt",
    },

    title: {
      height: "25pt",
      paddingHorizontal: "8pt",
      justifyContent: "center",
      borderBottom: "1pt solid #E1B53D",
    },
    subTitle: {
      height: "25pt",
      paddingHorizontal: "8pt",
      justifyContent: "center",
    },
    subtitleText: {
      fontFamily: "Inter",
      fontSize: "10pt",
      fontWeight: "200",
      color: "#192E54",
    },
    sep: {
      width: "100%",
      backgroundColor: "#B1CADA",
      height: "1pt",
      opacity: 0.5,
    },
    section: {
      marginBottom: "12pt",
    },
    checkbox: {
      borderRadius: "2pt",
      height: "10pt",
      width: "10pt",
      border: "1pt solid #626465",
    },
    check: {
      flexDirection: "row",
      gap: "5pt",
    },
    suggest: {
      flexDirection: "row",
      gap: "5pt",
    },
    mark: {
      width: "3pt",
      height: "3pt",
      borderRadius: "10pt",
      backgroundColor: "#192e54",
    },
    warn: {
      padding: "10pt 17pt",
      borderRadius: "10pt",
      backgroundColor: "#FFD9D9",
      marginBottom: "8pt",
    },
    "inner-content": {
      gap: "8pt",
      marginHorizontal: "20pt",
    },
    "white-space": {
      whiteSpace: "pre-line",
    },
  });
  return (
    <>
      <View style={[style.container, { marginTop: "10pt" }]}>
        <View style={style.title}>
          <Text
            style={{
              fontFamily: "InterSemBold",
              fontSize: "12pt",
              fontWeight: 600,
            }}
          >
            Main risks
          </Text>
        </View>
        {keyRisks.map((e, i) => (
          <View key={i} wrap={false} break={i != 0}>
            <View style={style.subTitle}>
              <Text style={style.subtitleText}>{e.type}</Text>
            </View>
            <View key={i} style={style.content}>
              <View style={style.section}>
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "10pt",
                    marginBottom: "16pt",
                    marginTop: "14pt",
                  }}
                >
                  {textdata.checkif} ...
                </Text>
                <View style={style["inner-content"]}>
                  {e.checkIf.split("\n-").map((s, k) => (
                    <View
                      key={k}
                      style={[
                        style.check,
                        exclusionCheck.includes(e.type) && k == 0 ? style.warn : {},
                        style["white-space"],
                      ]}
                      wrap={false}
                    >
                      <View
                        style={[
                          style.checkbox,
                          exclusionCheck.includes(e.type) && k == 0
                            ? { display: "none" }
                            : {},
                        ]}
                      ></View>
                      <Text key={k} style={[style.label, { flex: 1 }]}>
                        {s.indexOf("-") == 0
                          ? `${s.slice(1).trim()}`
                          : `${s.trim()}`}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={style.sep}></View>
              <View style={style.section}>
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "10pt",
                    marginBottom: "16pt",
                    marginTop: "14pt",
                  }}
                >
                  {textdata.suggestto} ...
                </Text>
                <View style={style["inner-content"]}>
                  {e.suggestTo.split("\n-").map((s, k) => (
                    <View
                      key={k}
                      style={[style.suggest, style["white-space"]]}
                      wrap={false}
                    >
                      <Text style={{ fontSize: "10pt" }}>
                        {exclusionSuggest.includes(e.type) && k == 0 ? "" : "•"}
                      </Text>
                      <View
                        style={[
                          { flex: 1 },
                          exclusionSuggest.includes(e.type) && k == 0
                            ? style.warn
                            : {},
                        ]}
                      >
                        <Text style={style.label} key={k}>
                          {s.startsWith("-")
                            ? `${s.slice(1).trim()}`
                            : `${s.trim()}`}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
      <View style={style.container} break>
        <Text
          style={{
            fontFamily: "InterBold",
            fontSize: "16pt",
            color: "#192E54",
            marginTop: "17pt",
          }}
        >
          Sector guidelines: {title}
        </Text>
        <Text
          style={{
            fontFamily: "InterBold",
            fontSize: "12pt",
            color: "#388098",
            marginTop: "10pt",
            marginBottom: "10pt",
          }}
        >
          Key E&S issues in portfolio
        </Text>
        <View style={[style.title, { borderBottom: "1pt solid #B1CADA" }]}>
          <Text style={{ fontSize: "12pt", fontWeight: "700" }}>
            Other potential risks
          </Text>
        </View>
        {otherRisks.map((e, i) => (
          <View key={i} break={i != 0}>
            <View style={[style.subTitle]}>
              <Text style={style.subtitleText}>{e.type}</Text>
            </View>
            <View key={i} title={e.type} style={style.content}>
              <View style={style.section}>
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "10pt",
                    marginBottom: "16pt",
                    marginTop: "14pt",
                  }}
                >
                  {textdata.checkif} ...
                </Text>
                <View style={style["inner-content"]}>
                  {e.checkIf.split("\n-").map((s, k) => (
                    <View
                      key={k}
                      style={[style.check, style["white-space"]]}
                      wrap={false}
                    >
                      <View style={style.checkbox}></View>
                      <Text key={k} style={[style.label, { flex: 1 }]}>
                        {s.indexOf("-") == 0
                          ? `${s.slice(1).trim()}`
                          : `${s.trim()}`}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={style.sep}></View>
              <View style={style.section}>
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "10pt",
                    marginBottom: "16pt",
                    marginTop: "14pt",
                  }}
                >
                  {textdata.suggestto} ...
                </Text>
                <View style={style["inner-content"]}>
                  {e.suggestTo.split("\n-").map((s, k) => (
                    <View
                      key={k}
                      style={[style.suggest, style["white-space"]]}
                      wrap={false}
                    >
                      <Text style={{ fontSize: "10pt" }}>
                        {exclusionSuggest.includes(e.type) && k == 0 ? "" : "•"}
                      </Text>
                      <View
                        style={[
                          { flex: 1 },
                          exclusionSuggest.includes(e.type) && k == 0
                            ? style.warn
                            : {},
                        ]}
                      >
                        <Text style={style.label} key={k}>
                          {s.startsWith("-")
                            ? `${s.slice(1).trim()}`
                            : `${s.trim()}`}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};

export default PDFGuidelines;
