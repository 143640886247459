import React from "react";
import style from "./style.module.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

const CardList = ({ desc, className, value, onChange }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      className={style["card-container"]}
      alignItems={"center"}
      gap={"20px"}
    >
      <span className={style.desc}>{desc}</span>
      <FormControl className={style.control}>
        <RadioGroup row onChange={(e) => onChange(e.target.value)}>
          <FormControlLabel
            value={"yes"}
            control={
              <Radio
                className={style.radio}
                checked={value == "yes"}
                size="small"
                sx={{ width: "24px", height: "24px" }}
              />
            }
            className={style.label}
            label="Yes"
          />
          <FormControlLabel
            value={"no"}
            control={
              <Radio
                className={style.radio}
                checked={value == "no"}
                size="small"
                sx={{ width: "24px", height: "24px" }}
              />
            }
            label="No"
            sx={{ marginRight: 0 }}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
export default CardList;
