import { convertFormattedStringToNumber } from "Utils/functions";
import { useCallback, useMemo } from "react";

const useCalculation = function (formdata) {
  const sum = useMemo(() => {
    let s = 0;

    s = Object.values(formdata).reduce((acc, curr) => {
      if (curr.value && curr.value != "")
        return convertFormattedStringToNumber(curr.value) + acc;
      else if (curr.subsectors)
        return (
          acc +
          Object.values(curr.subsectors).reduce((subacc, subcurr) => {
            if (subcurr != "") {
              return convertFormattedStringToNumber(subcurr) + subacc;
            } else return subacc;
          }, 0)
        );
      else return acc;
    }, 0);
    if (s == undefined || s == null) return 0;
    return s;
  }, [formdata]);

  const calculatePercentage = useCallback(
    (ind, subind) => {
      let val = 0;

      if (formdata[ind]) {
        if (formdata[ind].value && formdata[ind].value != "") {
          val = convertFormattedStringToNumber(formdata[ind].value);
        } else if (
          formdata[ind].subsectors &&
          formdata[ind].subsectors[subind] &&
          formdata[ind].subsectors[subind] != ""
        ) {
          val = convertFormattedStringToNumber(formdata[ind].subsectors[subind]);
        } else return "-";
      } else return "-";

      const perc = (val / sum) * 100;
      return perc;
    },
    [formdata]
  );

  return { calculatePercentage, sum };
};

export default useCalculation;
