import { formatNumber } from "Utils/functions";
import React from "react";
import style from "./style.module.css";
import { TextField } from "@mui/material";

function FormattedGeneralInput({ onChange, inputValue = "", error, helper, label }) {
  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length < inputValue.length) {
      onChange(formatNumber(value));
      return;
    }
    onChange(formatNumber(value));
  };

  return (
    <TextField
      value={inputValue}
      error={error}
      className={style.textfield}
      helperText={helper}
      label={label ? "Error" : null}
      onChange={handleChange}
    />
  );
}

export default FormattedGeneralInput;
