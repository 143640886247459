import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";

const PDFHeader = () => {
  const style = StyleSheet.create({
    pageHeader: {
      height: "55pt",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "10pt",
      marginBottom: "10pt",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      paddingHorizontal: "43pt",
      gap: "7pt",
      borderBottom: "1px solid #192E54",
    },
    logo: {
      width: "39pt",
      height: "29pt",
    },
    generated: {
      fontFamily: "Inter",
      color: "#fff",
      fontSize: "12pt",
      fontStyle: "italic",
    },
    popover: {
      backgroundColor: "#192e54",
      padding: "5pt",
    },
    label: {
      fontSize: "10pt",
      color: "#fff",
    },
    sectionTitle: {
      fontFamily: "InterSemBold",
      fontSize: "12pt",
      color: "#192e54",
      marginBottom: "10pt",
    },
  });
  return (
    <View style={style.pageHeader} fixed>
      <Image src={"/Images/logo_fmo.png"} />
      <View>
        <Text
          style={{
            fontSize: "12pt",
            fontFamily: "InterBold",
            color: "#388098",
          }}
        >
          FI ESG Tool
        </Text>
        <View
          style={{
            flexDirection: "row",
            fontFamily: "Inter",
            fontSize: "6",
            color: "#388098",
          }}
        >
          <Text>Powered by</Text>
          <Text style={{ fontFamily: "InterSemBold" }}> Steward Redqueen</Text>
        </View>
      </View>
    </View>
  );
};

export default PDFHeader;
