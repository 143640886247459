import Icon from "Components/Icon";
import style from "./style.module.css";
import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { formatNumberAsCurrency } from "Utils/functions";
import Modal from "Components/Modal";
import Contents from "./Contents";
import { BorderBotton } from "Components/StyledButton";
import { Popover, Stack, Typography } from "@mui/material";
import useTranslate from "Hooks/translate";
import { useSelector } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDoc from "./PdfDoc";

const ind = {
  Low: style.low,
  High: style.hight,
  Medium: style.med,
};

const iconMap = {
  "occupational health and safety": "occupational",
  "hazardous materials": "hazard",
  "water use and wastewater": "water",
  "air pollution": "airPollution",
  "impacts to community": "impact",
  "biodiversity and habitat": "biodiversity",
  "labor standards": "labor",
  "child and forced labor": "child",
  "exposure to disease / disease prevention": "desease",
  "animal welfare": "animal",
  "energy use": "energy",
  "waste management": "waste",
};

const CardDetail = ({ name, value, riskLevel, keyRisks, otherRisks, perc }) => {
  const [visible, setModalVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Popovercontent, setPopovercontent] = useState(null);
  const handlePopoverOpen = (event, content) => {
    setPopovercontent(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const open = Boolean(anchorEl);

  const textdata = useTranslate("RiskAssessement.portfolio.overview.detailed.modal");
  const curr = useSelector((state) => state.currency);

  const PDFDownloadLinkEL = useMemo(() => {
    return (
      <PDFDownloadLink
        document={
          <PdfDoc keyRisks={keyRisks} otherRisks={otherRisks} title={name} />
        }
        fileName={`Sector guidelines ${name}`}
      >
        <BorderBotton className={style.download}>
          <Icon icon={"download"} color={"green"} />
          {textdata.download}
        </BorderBotton>
      </PDFDownloadLink>
    );
  }, [keyRisks, otherRisks, name]);
  return (
    <>
      <Modal
        title={`${textdata.title} ${name}`}
        visible={visible}
        onClose={() => setModalVisible(false)}
        subtitle={textdata.subtitle}
        className={style.modal}
      >
        <Contents {...{ keyRisks, otherRisks }} />
        <Stack justifyContent={"center"} alignItems={"center"}>
          {PDFDownloadLinkEL}
        </Stack>
      </Modal>
      <div className={style.container}>
        <h3 className={style.title}>
          <span>{name}</span>
        </h3>
        <p className={style.total}>
          {`${formatNumberAsCurrency(curr, value.toString())}`}
        </p>
        <hr className={style.divide} />
        <div className={style["risk-level"]}>
          <span className={style["risk-level-title"]}>{textdata.riskLevel}:</span>
          <span className={style["risk-level-level"]}>
            <span className={classNames(ind[riskLevel], style.indicator)}></span>
            <span className={style["level-name"]}>{riskLevel}</span>
          </span>
        </div>
        <h3 className={style["main-risks-title"]}>{textdata.mainRisk}:</h3>
        <div className={style["main-risks"]}>
          {keyRisks.map((e, i) => (
            <div key={i}>
              <div
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(ev) => handlePopoverOpen(ev, e.type)}
                onMouseLeave={handlePopoverClose}
                className={style["icon-container"]}
              >
                <Icon icon={iconMap[e.type.toLowerCase()] || "default"} />
              </div>
              <Popover
                id="mouse-over-popover"
                PaperProps={{ elevation: 1 }}
                sx={{
                  pointerEvents: "none",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={open}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }}>{Popovercontent}</Typography>
              </Popover>
            </div>
          ))}
        </div>
        <hr className={style.divide} />
        <a className={style.guideline} href="#" onClick={handleClick}>
          {textdata.sectorGuidelines}
        </a>
      </div>
    </>
  );
};
export default CardDetail;
