import React from "react";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton, IconButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import style from "./style.module.css";

const ControlHeader = ({
  onNew,
  download,
  edit,
  newLabel,
  onEdit,
  disabled,
  title,
  downloadElem,
}) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography className={style.overview}>{title || "Overview"}</Typography>
      <Stack className={style["add-portfolio"]} direction={"row"} gap={"20px"}>
        {onNew && (
          <PrimaryButton disabled={disabled} onClick={onNew} className={style.add}>
            {newLabel || "Add New Data"}
          </PrimaryButton>
        )}
        {download &&
          (downloadElem || (
            <IconButton>
              <Icon icon="download" />
            </IconButton>
          ))}
        {edit && (
          <IconButton className={style.edit} onClick={onEdit}>
            <Icon icon="edit" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default ControlHeader;
