import style from "./style.module.css";
import React from "react";

const CardLibrary = ({ riskName, links }) => {
  return (
    <div className={style.container}>
      <h3>{riskName}</h3>
      {links.map((e, i) => {
        return (
          <span key={i}>
            <a target="_blank" rel="noreferrer" href={e.link}>
              {e.referenceName}
            </a>
          </span>
        );
      })}
    </div>
  );
};

export default CardLibrary;
