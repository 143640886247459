import { Stack } from "@mui/material";
import Modal from "Components/Modal";
import { PrimaryButton, SecondaryButton } from "Components/StyledButton";
import React from "react";
import style from "./style.module.css";

const ConfirmDelete = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      title={"Confirmation Required"}
      visible={visible}
      onClose={onCancel}
      className={style.confirm}
    >
      <p className={style.desc}>
        Please confirm that you want to delete this transaction
      </p>
      <Stack justifyContent={"space-between"} direction={"row"}>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onConfirm} className={style["btn-del"]}>
          Delete transaction
        </PrimaryButton>
      </Stack>
    </Modal>
  );
};

export default ConfirmDelete;
