import useTranslate from "Hooks/translate";
import React from "react";
import style from "../style.module.css";

const ForInsight = () => {
  const textdata = useTranslate("RiskAssessement.portfolio");

  return (
    <div className={style.visit}>
      <p>
        {textdata.forInsigh}&nbsp;
        <a target="_blank" href="https://www.jointimpactmodel.org/" rel="noreferrer">
          https://www.jointimpactmodel.org/
        </a>
      </p>
    </div>
  );
};

export default ForInsight;
