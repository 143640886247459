import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { allKeys } from "Utils/consts";
import interreg from "Assets/fonts/Inter-Regular.ttf";
import intersembold from "Assets/fonts/Inter-SemiBold.ttf";
import interitalic from "Assets/fonts/Inter-Italic.otf";
import interbold from "Assets/fonts/Inter-Bold.otf";
import PDFHeader from "Components/PDFHeader";
import PDFFooter from "Components/PDFFooter";

Font.register({
  family: "Inter",
  format: "truetype",
  src: interreg,
  fontWeight: "400",
});

Font.register({
  family: "Intersembold",
  format: "truetype",
  src: intersembold,
  fontWeight: "600",
});

Font.register({
  family: "Interbold",
  format: "truetype",
  src: interbold,
  fontWeight: "700",
});

Font.register({
  family: "Inter",
  format: "truetype",
  fontStyle: "italic",
  src: interitalic,
});

Font.registerHyphenationCallback((word) => [word]);

const OverviewStep = ({ step, index, suggestions }) => {
  const style = StyleSheet.create({
    stepHeader: {
      marginBottom: "26pt",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    steptitle: {
      fontSize: "12pt",
      color: "#192E54",
      fontFamily: "Intersembold",
      fontWeight: "600",
      marginLeft: "3pt",
    },
    mainContainer: {
      width: "100%",
      padding: "5pt 8pt 5pt 16pt",
      marginBottom: "10pt",
      borderRadius: "5pt",
    },
    container: {
      flexDirection: "column",
      gap: "15pt",
    },
    suggestionContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "30pt",
      alignItems: "flex-start",
    },
    suggestionTitle: {
      fontSize: "8pt",
      fontWeight: "600",
      fontFamily: "Intersembold",
      color: "#192E54",
    },
    suggestionDesc: {
      fontSize: "8pt",
      flexBasis: "80%",
      fontFamily: "Inter",
      color: "#192E54",
      lineHeight: 1.8,
    },
    progressWrapper: {
      flexBasis: "99pt",
      height: "9pt",
      border: "1pt solid #e1b53d",
      borderRadius: "90pt",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  });
  return (
    <View style={style.mainContainer} wrap={false}>
      <View>
        <View style={style.stepHeader}>
          <Text style={style.steptitle}>{`Step ${index}: ${step.title}`}</Text>
        </View>
        <View style={[style.container, { marginBottom: "22pt" }]}>
          <View wrap={false} style={style.suggestionContainer}>
            <Text
              style={[
                style.suggestionTitle,
                { flexBasis: "22%", marginLeft: "3pt", fontSize: "10pt" },
              ]}
            >
              Alignment Status
            </Text>
            <Text
              style={[
                style.suggestionTitle,
                { flexBasis: "50%", marginLeft: "3pt", fontSize: "10pt" },
              ]}
            >
              What can you do to improve?
            </Text>
            <View style={style.progressWrapper}>
              <View
                style={{
                  width: suggestions.progress + 1 + "%",
                  minWidth: "15%",
                  height: "9pt",
                  backgroundColor: "#e1b53d",
                  borderRadius: "90pt",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "7pt",
                    zIndex: 9999,
                    position: "absolute",
                    fontWeight: "600",
                    fontFamily: "Intersembold",
                    color: "#fff",
                  }}
                >
                  {suggestions.progress + "%"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={style.container}>
          {step.levels.map((e, i) => {
            const suggestion =
              suggestions.dimensionValues[allKeys[index][i]].suggestion;
            const userInput =
              suggestions.dimensionValues[allKeys[index][i]].userInput;
            return (
              <View wrap={false} style={style.suggestionContainer} key={i}>
                <View
                  style={{
                    flexBasis: "20%",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5pt",
                  }}
                >
                  <View
                    style={{
                      width: "20pt",
                      height: "10pt",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {userInput <= 1 ? (
                      <Image
                        src={"/Images/IC_Close.jpg"}
                        style={{ width: "10pt", height: "10pt" }}
                      />
                    ) : (
                      <Image
                        src={"/Images/IC_Checkmark.png"}
                        style={{ width: "10pt", height: "8pt" }}
                      />
                    )}
                  </View>
                  <Text style={style.suggestionTitle}>{e.title}</Text>
                </View>
                <Text style={style.suggestionDesc}>{suggestion}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const MyDoc = ({ steps, suggestions }) => {
  return (
    <Document>
      <Page style={{ padding: "60pt 42pt" }}>
        <PDFHeader />
        <Text
          style={{
            color: "#192E54",
            fontWeight: "700",
            fontSize: "16pt",
            fontFamily: "Interbold",
            marginBottom: "15pt",
          }}
        >
          UNPRB self-assessment results overview
        </Text>
        <View>
          {steps.map((e, i) => {
            return (
              <OverviewStep
                key={i}
                step={e}
                index={i}
                suggestions={suggestions[i]}
              />
            );
          })}
        </View>
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default MyDoc;
