import First from "./First";
import Second from "./Second";
import Third from "./Third";
import React, { useState } from "react";

const AccordionContents = () => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (ex) => () => {
    setExpanded((prev) => (ex == prev ? null : ex));
  };

  return (
    <>
      <First expanded={expanded == 0} onChange={handleChange(0)} />
      <Second expanded={expanded == 1} onChange={handleChange(1)} />
      <Third expanded={expanded == 2} onChange={handleChange(2)} />
    </>
  );
};

export default AccordionContents;
