import Table from "Components/CustomTable";
import React from "react";
import style from "./style.module.css";
import Icon from "Components/Icon";
import classNames from "classnames";
import useTranslate from "Hooks/translate";

const risk = {
  High: style.hight,
  Medium: style.med,
  Low: style.low,
};

const TransactionsTable = ({ data, onDelete, onEdit, onExpand }) => {
  const textdata = useTranslate("RiskAssessement.transactions.overview.table");
  const header = textdata.header;
  const config = {
    rowClass: [style["transaction-row"], style["table-transaction-row"]],
    showHeader: true,
    headerClass: style.header,
    columns: [
      {
        dataindex: "companyName",
        className: style["company-name"],
        span: 16.5,
        title: header.transaction,
        onClick: (rec, data, ind) => {
          onExpand(ind);
        },
        render: (rec, data) => (
          <>
            <Icon icon={"expand"} />
            <span>{data}</span>
          </>
        ),
      },
      {
        dataindex: "subSector",
        title: header.subSect,
        className: style["sub-sector"],
        span: 20.3,
      },
      {
        dataindex: "socialRiskLevel",
        title: header.eandsrisk,
        className: style["social-risk"],
        span: 16.06,
        render: (rec, data) => [
          <span key={0} className={classNames([style.risk, risk[data]])}></span>,
          <span key={1}>{data}</span>,
        ],
      },
      {
        dataindex: "governanceRiskLevel",
        title: header.corprisk,
        className: style["govern-risk"],
        render: (rec, data) => [
          <span key={0} className={classNames([style.risk, risk[data]])}></span>,
          <span key={1}>{data}</span>,
        ],
        span: 14.47,
      },
      {
        dataindex: "suggestedESRiskManagementApproach",
        title: header.suggested,
        className: style["suggest-approach"],
        span: 25.31,
      },
      {
        span: 7,
        className: style.controls,
        render: (rec, data, ind) => {
          return [
            <Icon
              key={0}
              icon="del"
              onClick={() => {
                onDelete(ind);
              }}
            />,
            <Icon
              key={1}
              icon="modif"
              onClick={() => {
                onEdit(ind);
              }}
            />,
          ];
        },
      },
    ],
  };
  return <Table data={data} config={config} />;
};

export default TransactionsTable;
