import React from "react";
import WhiteLogo from "Components/Logo/WhiteLogo";
import style from "./style.module.css";
import { getYear } from "Utils/functions";

const HomeFooter = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.footer}>
          <div className={style.down}>
            <a target="_blank" href="/240108_FAQ_ESG-Tool_v1.pdf">
              <p>Download FAQ</p>
            </a>
            <a href="mailto:support@stewardredqueen.com">Contact Us</a>
          </div>
          <p className={style.copyright}>
            © {getYear()} FMO FI ESG Tool. All rights reserved
          </p>
          <WhiteLogo showPowered />
        </div>
        <hr />
        <p className={style.desc}>
          Whilst FMO has taken reasonable care and diligence in developing this ESG
          tool, it should not be considered as exhaustive or as any form of advice.
          It is designed to be informative, and the user will have to make its own
          independent judgment as whether to rely thereon. Accordingly, FMO does not
          accept any liability for the use of this ESG tool and subsequent actions
          based thereon. By using this ESG tool, the user agrees to these terms.
        </p>
      </div>
    </div>
  );
};

export default HomeFooter;
