import { createSlice } from "@reduxjs/toolkit";

const assesmentFormSlice = createSlice({
  name: "assesmentForm",
  initialState: {},
  reducers: {
    setSelected: (state, action) => {
      return {
        ...state,
        [action.payload.step]: {
          ...state[action.payload.step],
          [action.payload.option]: action.payload.selected,
        },
      };
    },
    clearUnprbForm: () => {
      return {};
    },
  },
});

export const { setSelected, clearUnprbForm } = assesmentFormSlice.actions;
export default assesmentFormSlice.reducer;
