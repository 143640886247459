import React from "react";
import style from "./style.module.css";

const ItemTransaction = ({ title, desc }) => {
  return (
    <div className={style.container}>
      <span className={style.title}>{title}</span>
      <span className={style.desc}>{desc}</span>
    </div>
  );
};

export default ItemTransaction;
