import { Route, Routes } from "react-router";
import Footer from "Components/Footer";
import Navbar from "Components/Navbar";
import RiskAssesment from "Pages/RiskAssesment";
import GrievanceMechanism from "Pages/GrievanceMechanism";
import UNPRB from "Pages/UNPRB";
import { CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";

const App = () => {
  const lang = useSelector((state) => state.language);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  return (
    <>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/RiskAssesment/*" element={<RiskAssesment />} />
        <Route path="/GrievanceMechanism/*" element={<GrievanceMechanism />} />
        <Route path="/UNPRB/*" element={<UNPRB />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
