import React from "react";
import WhiteLogo from "Components/Logo/WhiteLogo";
import style from "./style.module.css";
import { getYear } from "Utils/functions";

const Footer = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <hr />

        <div className={style.footer}>
          <div className={style.down}>
            <a target="_blank" href="/240108_FAQ_ESG-Tool_v1.pdf">
              <p>Download FAQ</p>
            </a>
            <a href="mailto:support@stewardredqueen.com">Contact Us</a>
          </div>
          <p className={style.copyright}>
            © {getYear()} FMO FI ESG Tool. All rights reserved
          </p>
          <WhiteLogo showPowered />
        </div>
      </div>
    </div>
  );
};

export default Footer;
