import style from "./style.module.css";
import React from "react";

const Progress = ({ progress, className }) => {
  return (
    <div className={[style.wrapper, className].join(" ")}>
      <div className={style.progress} style={{ width: progress + "%" }}>
        {progress + "%"}
      </div>
    </div>
  );
};

export default Progress;
