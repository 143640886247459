import classNames from "classnames";
import style from "./style.module.css";
import React, { useEffect, useState } from "react";
import Icon from "Components/Icon";
import FormattedInput from "Components/FormattedInput";
import { useDispatch, useSelector } from "react-redux";
import { setExp } from "reducers/tempportfoliodata";
import useCalculation from "Hooks/calculation";

const useSectorTableConfig = (formdata, visible) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const dispatch = useDispatch();
  const curr = useSelector((state) => state.currency);

  useEffect(() => {
    setExpandedRow(null);
  }, [visible]);

  const { calculatePercentage } = useCalculation(formdata);

  function handleSectorChange(ind, val) {
    dispatch(setExp({ ind, val }));
  }
  function handleSubSectorChange(ind, subind, val) {
    dispatch(setExp({ ind, subind, val }));
  }

  const config = {
    rowClass: style["category-row"],
    headerClass: style["table-header"],
    showHeader: true,
    expandedRow,
    columns: [
      {
        title: "Sector",
        dataindex: "title",
        className: (rec) => {
          return classNames(style["category-title"], {
            [style["category-title-collapsible"]]: rec.subsectors,
          });
        },
        headerClass: style["header-title"],
        span: 45,
        onClick: (rec, data, ind) => {
          setExpandedRow(ind == expandedRow ? null : ind);
        },
        render: (rec, data, ind) => {
          return [
            <span key={0}>{data}</span>,
            rec.subsectors && (
              <Icon
                key={1}
                icon="chevronDown"
                className={classNames(style.collapse, {
                  [style.collapsed]: ind != expandedRow,
                })}
              />
            ),
          ];
        },
      },
      {
        title: `Volume (${curr.toUpperCase()})`,
        dataindex: "editable",
        className: style.edit,
        headerClass: style["header-title"],
        span: 27.5,
        render: (rec, editable, ind) => {
          return (
            editable && (
              <FormattedInput
                inputValue={formdata[ind]?.value}
                onChange={(val, prevval) => {
                  if (prevval == "0" && val != "0." && val != "00") {
                    handleSectorChange(
                      ind,
                      val
                        .split("0")
                        .filter((e) => e != "")
                        .join()
                    );
                  } else if (val == "00") {
                    handleSectorChange(ind, "0");
                  } else handleSectorChange(ind, val);
                }}
                className={style.input}
              />
            )
          );
        },
      },
      {
        title: "Percentage",
        className: style.percentage,
        headerClass: style["header-title"],
        span: 27.5,
        render: (rec, data, ind) => {
          let perc = calculatePercentage(ind);
          if (!Number.isNaN(perc) && typeof perc == "number") perc = perc.toFixed(2);
          return rec.editable && (perc || "-") + "%";
        },
      },
    ],
    subColumns: [
      {
        dataindex: "subsectors",
        rowClass: style["sub-sector"],
        columns: [
          { dataindex: "title", className: style["sub-category-title"], span: 45 },
          {
            className: style.edit,
            span: 27.5,
            render: (rec, dat, ind, subind) => {
              return (
                <FormattedInput
                  onChange={(val, prevval) => {
                    if (prevval == "0" && val != "0." && val != "00") {
                      handleSubSectorChange(
                        ind,
                        subind,
                        val
                          .split("0")
                          .filter((e) => e != "")
                          .join()
                      );
                    } else if (val == "00") {
                      handleSubSectorChange(ind, subind, "0");
                    } else handleSubSectorChange(ind, subind, val);
                  }}
                  inputValue={formdata[ind]?.subsectors[subind]}
                  className={style.input}
                />
              );
            },
          },
          {
            dataindex: "percentage",
            className: style.percentage,
            span: 27.5,
            render: (rec, data, ind, subind) => {
              let perc = calculatePercentage(ind, subind);
              if (!Number.isNaN(perc) && typeof perc == "number")
                perc = perc.toFixed(2);
              return (perc || "-") + "%";
            },
          },
        ],
      },
    ],
  };
  return [config];
};

export default useSectorTableConfig;
