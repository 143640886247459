import iconMap from "Utils/iconMap";
import React from "react";
const Icon = ({ width, height, icon, color, className, onClick }) => {
  function getIcon() {
    return (
      (typeof iconMap[icon] === "string" && iconMap[icon]) ||
      iconMap[icon][color] ||
      iconMap[icon].light
    );
  }

  return (
    <img
      className={className}
      src={getIcon()}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

Icon.defaultProps = {
  width: "auto",
  height: "auto",
  onClick: () => {},
};

export default Icon;
