import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { PrimaryButton } from "Components/StyledButton";
import React from "react";
import style from "./style.module.css";

const Home = () => {
  return (
    <Stack alignItems={"center"} className={style.section}>
      <Link to={"form"} className={style.link}>
        <PrimaryButton className={style["self-assesment"]}>
          Start self-assessment
        </PrimaryButton>
      </Link>
    </Stack>
  );
};

export default Home;
