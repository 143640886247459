import React from "react";
import style from "./style.module.css";

const CardContainer = ({ title, children }) => {
  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      {children}
    </div>
  );
};

export default CardContainer;
