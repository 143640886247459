import { Link } from "react-router-dom";
import style from "./style.module.css";
import React from "react";
import classNames from "classnames";
import useTranslate from "Hooks/translate";
import HomeFooter from "Components/HomeFooter";
import WhiteLogo from "Components/Logo/WhiteLogo";

const HomePage = () => {
  const textdata = useTranslate("home");

  return (
    <>
      <div className={style["main-container"]}>
        <div className={style.background}></div>
        <div className={style.container}>
          <div className={style.navbar}>
            <WhiteLogo />
            <ul className={style.nav}>
              <li>
                <Link to="/RiskAssesment">ESG Risk Assessment</Link>
              </li>
              <li>
                <Link to="/GrievanceMechanism">External Grievance Mechanism</Link>
              </li>
              <li>
                <Link to="/UNPRB">UNPRB</Link>
              </li>
              <li></li>
            </ul>
          </div>
          <h1 className={style.title}>{textdata.title}</h1>
          <p className={style.desc}>{textdata.desc}</p>
        </div>
        <div className={classNames(style.container, style.position)}>
          <div className={classNames(style.container, style["card-container"])}>
            {textdata.cards.map((e, i) => (
              <div className={style.card} key={i}>
                <h3>{e.title}</h3>
                <p>{e.desc}</p>
                <Link to={e.to}>Start</Link>
              </div>
            ))}
          </div>
          <p className={style.signup}>
            <a
              className={style["signup-link"]}
              href="https://www.fmo.nl/your-own-esms "
              target="blank"
            >
              {textdata.signup}
            </a>
          </p>
        </div>
      </div>
      <div className={style.footer}>
        <HomeFooter />
      </div>
    </>
  );
};

export default HomePage;
