import api from "Api";
import { GENERATE_FORM_DATA } from "Api/endpoints";
import { useEffect, useState } from "react";

const useFormField = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    api.get(GENERATE_FORM_DATA).then((resp) => {
      setData(resp.data.data);
      setLoading(false);
    });
  }, []);

  return [data, loading];
};

export default useFormField;
