import { AccordionPrimary } from "Components/Accordion";
import React from "react";
import style from "./index.module.css";

const Second = ({ onChange, expanded, Img }) => {
  return (
    <AccordionPrimary
      title={"Suggested E&S risk management approach"}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      <div className={style.container}>{Img}</div>
    </AccordionPrimary>
  );
};

export default Second;
