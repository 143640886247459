import { Route, Routes } from "react-router-dom";
import React from "react";
import { Container } from "@mui/material";
import Portfolio from "./Portfolio";
import Transactions from "./Transactions";
import AssessmentNav from "Components/AssessmNav";
import style from "./style.module.css";

const Assessement = () => {
  return (
    <Container maxWidth="lg" className={style.container}>
      <AssessmentNav />
      <Routes>
        <Route path="PortfolioAssessment" element={<Portfolio />} />
        <Route path="TransactionAssessment" element={<Transactions />} />
      </Routes>
    </Container>
  );
};

export default Assessement;
