import { Container, Stack } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import { IconButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import Logo from "Components/Logo/Logo";
import style from "./style.module.css";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { switchCurrency } from "reducers/currency";

const Navbar = () => {
  const { t } = useTranslation();
  const trans = t("Navbar", { returnObjects: true });
  const currency = useSelector((state) => state.currency);
  const dispatch = useDispatch();

  return (
    <Container maxWidth={"lg"} className={style.container}>
      <Stack alignItems={"center"} direction={"row"}>
        <Link to={"/"} className={style.home}>
          <Logo />
        </Link>
        <NavLink
          to={"/RiskAssesment"}
          className={({ isActive }) =>
            classNames(style["nav-link"], { [style.active]: isActive })
          }
        >
          {trans[0]}
        </NavLink>
        <NavLink
          to={"/GrievanceMechanism"}
          className={({ isActive }) =>
            classNames(style["nav-link"], { [style.active]: isActive })
          }
        >
          {trans[1]}
        </NavLink>
        <NavLink
          to={"/UNPRB"}
          className={({ isActive }) =>
            classNames(style["nav-link"], { [style.active]: isActive })
          }
        >
          {trans[2]}
        </NavLink>
        <div>
          <IconButton
            className={style.eur}
            onClick={() => {
              dispatch(switchCurrency());
            }}
          >
            <Icon icon={currency == "usd" ? "usd" : "eur"} />
          </IconButton>
        </div>
      </Stack>
    </Container>
  );
};

export default Navbar;
