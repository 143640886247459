import { AccordionPrimary } from "Components/Accordion";
import style from "./style.module.css";
import React from "react";
import CardLibrary from "Components/CardLibrary";

const Fourth = ({ onChange, expanded, library }) => {
  return (
    <AccordionPrimary
      title={"Library"}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      <div className={style.container}>
        {library.map((e, i) => {
          return <CardLibrary {...e} key={i} />;
        })}
      </div>
    </AccordionPrimary>
  );
};

export default Fourth;
