import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const portfoliodata = createSlice({
  name: "portfoliodata",
  initialState,
  reducers: {
    savePortfolio: (state, action) => {
      return action.payload;
    },
    clearPortfolio: () => {
      return initialState;
    },
  },
});

export default portfoliodata.reducer;
export const { savePortfolio, remPortfolio, clearPortfolio } = portfoliodata.actions;
