import { Input } from "@mui/material";
import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

const CustomInput = ({ value = "", onChange, className, placeholder }) => {
  return (
    <Input
      placeholder={placeholder}
      type="text"
      onChange={onChange}
      value={value}
      className={classNames(style.input, className)}
    />
  );
};

export default CustomInput;
