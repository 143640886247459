import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Hero from "Components/Hero";
import style from "./style.module.css";
import PrincipleCard from "Components/PrincipleCard";
import { BorderBotton } from "Components/StyledButton";
import Icon from "Components/Icon";
import { Route, Routes } from "react-router-dom";
import Form from "./Form";
import Overview from "./Overview";
import Home from "./Home";
import { useTranslation } from "react-i18next";

const UNPRB = () => {
  const { t } = useTranslation();
  const hero = t("UNPRB.hero", { returnObjects: true });
  return (
    <>
      <Hero
        className={style.hero}
        maxWidth={false}
        containerClassName={style.container}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={"10px"}
        >
          <Typography variant="h2" className={style["main-title"]}>
            {hero.title}
          </Typography>
          <img src="/Images/unep.png" />
        </Stack>
        <Typography className={style.desc}>{hero.desc}</Typography>
        <Typography className={style.subtitle} variant="h2">
          {hero.subtitle}
        </Typography>
        <Stack direction={"row"} className={style.principles}>
          {hero.principles.map((e, i) => {
            return (
              <PrincipleCard
                icon={e.icon}
                title={e.title}
                desc={e.desc}
                key={i}
                className={style.card}
              />
            );
          })}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          {hero.actions.map((e, i) => (
            <a
              target="_blank"
              href={e.href}
              key={i}
              className={style.link}
              rel="noreferrer"
            >
              <BorderBotton
                className={style.btn}
                startIcon={e.icon && <Icon color={"green"} icon={e.icon} />}
              >
                {e.text}
              </BorderBotton>
            </a>
          ))}
        </Stack>
      </Hero>
      <Container className={style.page} maxWidth="xl">
        <Routes key={1}>
          <Route index element={<Home />} key={0} />
          <Route path="form" element={<Form />} key={1} />
          <Route path="overview" element={<Overview />} key={2} />
        </Routes>
      </Container>
    </>
  );
};

export default UNPRB;
