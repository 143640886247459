import { Stack, Typography } from "@mui/material";
import Icon from "Components/Icon";
import style from "./style.module.css";
import React from "react";

const PrincipleCard = ({ icon, title, desc, className }) => {
  return (
    <Stack gap={"15px"} alignItems={"center"} className={className}>
      <Icon icon={icon} width={"50px"} height={"50px"} />
      <Typography variant="h3" className={style.title}>
        {title}
      </Typography>
      <Typography className={style.desc}>{desc}</Typography>
    </Stack>
  );
};

export default PrincipleCard;
