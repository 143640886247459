import React, { memo, useEffect, useState } from "react";
import NewTransaction from "./NewTransaction";
import ControlHeader from "Components/ControlHeader";
import { useDispatch, useSelector } from "react-redux";
import Overview from "./Overview";
import useTranslate from "Hooks/translate";
import { clearTempTrans, setTempTransaction } from "reducers/temptransactiondata";
import { IconButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "./pdfDoc";
import { CircularProgress } from "@mui/material";
import style from "./style.module.css";
import { clearTransactions } from "reducers/transactionsData";

const Transactions = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [recToEdit, setRecToEdit] = useState(null);
  const [expanded, setExpanded] = useState(0);

  const transactionsData = useSelector((state) => state.transactionsdata);
  const textdata = useTranslate("RiskAssessement.transactions.add");
  const dispatch = useDispatch();
  const refreshAt = useSelector((state) => state.refresh);

  useEffect(() => {
    if (refreshAt.transactionRefreshAt < Date.now()) {
      dispatch(clearTransactions());
    }
  }, []);

  const Pdfdocel = memo(function PdfDocEl({ transdet }) {
    return (
      <PDFDownloadLink
        document={<MyDoc details={transdet} />}
        fileName="Transactions_list.pdf"
      >
        {({ blob, url, loading, error }) => (
          <IconButton>
            {loading ? (
              <CircularProgress className={style.loader} size={"32px"} />
            ) : (
              <Icon icon="download" />
            )}
          </IconButton>
        )}
      </PDFDownloadLink>
    );
  });

  return (
    <>
      <ControlHeader
        onNew={() => {
          setModalVisible(true);
        }}
        newLabel={textdata}
        download={transactionsData?.resultData.length != 0}
        disabled={transactionsData.userData.length == 3}
        downloadElem={<Pdfdocel transdet={transactionsData} />}
      />
      <Overview
        onEdit={(i) => {
          setRecToEdit(i);
          dispatch(setTempTransaction(transactionsData.userData[i]));
          setEdit(true);
          setModalVisible(true);
        }}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <NewTransaction
        edit={edit}
        ind={recToEdit}
        visible={modalVisible}
        onCreate={() => setExpanded(transactionsData.userData.length)}
        onClose={() => {
          setEdit(false);
          setModalVisible(false);
          dispatch(clearTempTrans());
        }}
      />
    </>
  );
};

export default Transactions;
