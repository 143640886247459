import { floatRegex, formattedfloatRegex } from "Utils/consts";
import useTranslate from "./translate";

const useValidateSectExpo = (formdata, dummydata) => {
  const userinput = formdata;

  return dummydata.every((e, i) => {
    if (e.subsectors && userinput[i]?.subsectors) {
      return e.subsectors.every((l, j) => {
        formattedfloatRegex.lastIndex = 0;
        return (
          formattedfloatRegex.test(userinput[i].subsectors[j]) &&
          userinput[i].subsectors[j] != ""
        );
      });
    } else {
      formattedfloatRegex.lastIndex = 0;
      return (
        formattedfloatRegex.test(userinput[i]?.value) && userinput[i]?.value != ""
      );
    }
  });
};

const useValidateExpNPerm = (formdata) => {
  const dummydata = useTranslate("RiskAssessement.portfolio.expNPerm");
  return dummydata.length == Object.keys(formdata).length;
};

const useValidateExpPerm = (formdata) => {
  const dummydata = useTranslate("RiskAssessement.portfolio.expPerm");
  return dummydata.every((e, i) => {
    floatRegex.lastIndex = 0;
    return formdata[i] && floatRegex.test(formdata[i]);
  });
};

export { useValidateSectExpo, useValidateExpNPerm, useValidateExpPerm };
