import React, { useMemo } from "react";
import style from "./style.module.css";
import { useDispatch } from "react-redux";
import { setPermExp } from "reducers/tempportfoliodata";
import FormattedInputPercent from "Components/FormattedInputPercent";
import { floatRegex } from "Utils/consts";

const useExpPermConf = (formdata, setValidErr) => {
  const dispatch = useDispatch();

  const conf = useMemo(() => {
    return {
      rowClass: style.row,
      columns: [
        {
          span: 70,
          className: style.desc,
          dataindex: "desc",
        },
        {
          span: 30,
          className: style.control,
          render: (rec, dat, ind) => {
            return (
              <FormattedInputPercent
                inputValue={formdata[ind]}
                className={style.input}
                onChange={(value) => {
                  floatRegex.lastIndex = 0;
                  if (value == "%") dispatch(setPermExp({ [ind]: "" }));
                  else if (floatRegex.test(value)) {
                    setValidErr(false);
                    dispatch(setPermExp({ [ind]: value }));
                  }
                }}
              />
            );
          },
        },
      ],
    };
  }, [formdata]);

  return conf;
};

export default useExpPermConf;
