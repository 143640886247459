import { combineReducers, configureStore } from "@reduxjs/toolkit";

import languageReducer from "reducers/language";
import themeReducer from "reducers/theme";
import assesmentFormReducer from "reducers/assesmentForm";
import tempportfolioReducer from "reducers/tempportfoliodata";
import portfoliodataReducer from "reducers/portfoliodata";
import editPortfolioReducer from "reducers/currendPortfolio";
import temptransactiondataReducer from "reducers/temptransactiondata";
import transactionsDataReducer from "reducers/transactionsData";
import currencyReducer from "reducers/currency";
import refreshReducer from "reducers/refreshData";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  blacklist: ["tempportfoliodata", "temptransactiondata"],
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  language: languageReducer,
  theme: themeReducer,
  assesmentForm: assesmentFormReducer,
  tempportfoliodata: tempportfolioReducer,
  portfoliodata: portfoliodataReducer,
  currentPortfolio: editPortfolioReducer,
  temptransactiondata: temptransactiondataReducer,
  transactionsdata: transactionsDataReducer,
  currency: currencyReducer,
  refresh: refreshReducer,
});

const persistedRoot = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRoot,
  middleware: [thunk],
});

export const persistor = persistStore(store);
