import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { allKeys } from "Utils/consts";
import { generateResult } from "Api/apiCalls";

const useSuggestions = () => {
  const selectedItems = useSelector((state) => state.assesmentForm);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const reqbody = {};

    for (const key in selectedItems) {
      const stepDataKey = `step${key}Data`;
      const dimensionValues = {};
      for (const skey in selectedItems[key]) {
        dimensionValues[allKeys[key][skey]] = {
          userInput: selectedItems[key][skey],
        };
      }
      reqbody[stepDataKey] = { dimensionValues };
    }

    generateResult(reqbody, (data) => {
      const aux = [];
      for (const key in data) aux.push(data[key]);
      setSuggestions(aux);
      setLoading(false);
    });
  }, []);

  return [loading, suggestions];
};

export default useSuggestions;
