import CustomInput from "Components/Input";
import classNames from "classnames";
import React, { useRef } from "react";
import style from "./style.module.css";

function FormattedInputPercent({ onChange, className, inputValue = "" }) {
  const prevvalue = useRef();

  const handleChange = (event) => {
    const rawValue = event.target.value;
    let formattedValue = rawValue.length != 0 ? rawValue.replace("%", "") + "%" : "";
    if (rawValue.length == 0) formattedValue = "";
    else if (rawValue.length < inputValue.length) {
      formattedValue = inputValue.slice(1);
    }

    onChange(formattedValue, prevvalue.current);
    prevvalue.current = formattedValue;
  };

  return (
    <CustomInput
      value={inputValue}
      onChange={handleChange}
      className={classNames(className, {
        [style.empty]: inputValue.length == 0,
      })}
    />
  );
}

export default FormattedInputPercent;
