import IC_Download from "Assets/Icons/IC_Download.svg";
import IC_Download_Light from "Assets/Icons/IC_Download_Light.svg";
import IC_ChevronDown from "Assets/Icons/IC_ChevronDown.svg";
import IC_Alignment from "Assets/Icons/IC_Align.png";
import IC_Target from "Assets/Icons/IC_Target.png";
import IC_Client from "Assets/Icons/IC_Client.png";
import IC_Stackholder from "Assets/Icons/IC_Stackholder.png";
import IC_Governance from "Assets/Icons/IC_Governance.png";
import IC_Search from "Assets/Icons/IC_Search.png";
import IC_Checkmark from "Assets/Icons/IC_Checkmark.svg";
import IC_Close from "Assets/Icons/IC_Close.svg";
import IC_EUR from "Assets/Icons/IC_EUR.svg";
import IC_Quit from "Assets/Icons/IC_Quit.svg";
import IC_Pen from "Assets/Icons/IC_Pen.svg";
import IC_Warn from "Assets/Icons/IC_Warn.svg";
import IC_Delete from "Assets/Icons/IC_Delete.svg";
import IC_Edit from "Assets/Icons/IC_Edit.svg";
import IC_Expand from "Assets/Icons/IC_Expand.svg";
import IC_Inf from "Assets/Icons/IC_Info.svg";
import IC_USD from "Assets/Icons/IC_USD.png";

const IMG_chimic = "/Images/img_chimicals.png";
const IMG_tree = "/Images/img_trees.png";
const IC_ImpactsToCommunity = "/Images/IC_ImpactsToCommunity.png";
const IC_HazardousMaterial = "/Images/IC_HazardousMaterial.png";
const IC_LaborStandard = "/Images/IC_LaborStandard.png";
const IC_BiodiversityAndHabitate = "/Images/IC_BiodiversityAndHabitate.png";
const IC_ChildAndForcedLabor = "/Images/IC_ChildAndForcedLabor.png";
const IC_AirPollution = "/Images/IC_AirPollution.png";
const IC_WaterUseAndWasteWater = "/Images/IC_WaterUseAndWasteWater.png";
const IC_OccupationalHealthAndSafety = "/Images/IC_OccupationalHealthAndSafety.png";
const IC_DiseasePrevention = "/Images/IC_DiseasePrevention.png";
const IC_AnimalWelfare = "/Images/IC_AnimalWelfare.png";
const IC_EnergyConsumption = "/Images/IC_EnergyConsumption.png";
const IC_WasteManagement = "/Images/IC_WasteManagement.png";

const iconMap = {
  tree: IMG_tree,
  chimic: IMG_chimic,
  warn: IC_Warn,
  download: {
    green: IC_Download,
    light: IC_Download_Light,
  },
  edit: IC_Pen,
  quit: IC_Quit,
  eur: IC_EUR,
  usd: IC_USD,
  chevronDown: IC_ChevronDown,
  alignment: IC_Alignment,
  checkmark: IC_Checkmark,
  close: IC_Close,
  target: IC_Target,
  client: IC_Client,
  stakeholders: IC_Stackholder,
  governance: IC_Governance,
  search: IC_Search,
  del: IC_Delete,
  modif: IC_Edit,
  expand: IC_Expand,
  inf: IC_Inf,
  impact: IC_ImpactsToCommunity,
  hazard: IC_HazardousMaterial,
  labor: IC_LaborStandard,
  biodiversity: IC_BiodiversityAndHabitate,
  child: IC_ChildAndForcedLabor,
  airPollution: IC_AirPollution,
  water: IC_WaterUseAndWasteWater,
  occupational: IC_OccupationalHealthAndSafety,
  desease: IC_DiseasePrevention,
  animal: IC_AnimalWelfare,
  energy: IC_EnergyConsumption,
  waste: IC_WasteManagement,
  default: "/Images/default.png",
};

export default iconMap;
