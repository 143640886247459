import CardRisk from "Components/CardRisk";
import React from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import CardDetail from "Components/CardDetail";
import useTranslate from "Hooks/translate";
import CardLibrary from "Components/CardLibrary";

const DetailedPortfolio = () => {
  const portfoliodata = useSelector((state) => state.portfoliodata);

  const textdata = useTranslate("RiskAssessement.portfolio.overview.detailed");
  return (
    <CardRisk
      title={textdata.title}
      subtitle={textdata.subtitle}
      className={style["detailed-exposure"]}
    >
      <div className={style["risk-container"]}>
        {portfoliodata.riskiestSectors.map((e, i) => {
          return <CardDetail key={i} {...e} />;
        })}
      </div>
      <CardRisk
        subtitle={"Key E&S issues in portfolio"}
        className={style["key-issues"]}
      >
        <div className={style.container}>
          {portfoliodata.keyIssues.map((e, i) => {
            return <CardLibrary {...e} key={i} />;
          })}
        </div>
      </CardRisk>
    </CardRisk>
  );
};

export default DetailedPortfolio;
