import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainThemeProvider from "MainThemeProvider";
import App from "App";
import "i18n";
import "index.css";
import { StyledEngineProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { persistor, store } from "store.js";
import { PersistGate } from "redux-persist/integration/react";
import HomePage from "Pages/Home";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainThemeProvider>
          <StyledEngineProvider injectFirst>
            <Routes>
              <Route path="/*" element={<App />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </StyledEngineProvider>
        </MainThemeProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
