import CardRisk from "Components/CardRisk";
import React from "react";
import style from "./style.module.css";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useChartOptions, { usePDFChartOptions } from "./chartOptions.js";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useTranslate from "Hooks/translate";

const tooltipNote = {
  id: "getpng",
  afterDraw: (chart, args, options) => {
    if (options.updated) {
      options.saveImage(chart.toBase64Image());
    }
  },
};

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels, tooltipNote);

const SectorExposure = ({ onImageSectorExposure }) => {
  const sectorExposure = useSelector((state) => state.portfoliodata.sectorExposure);
  const [data, options] = useChartOptions(sectorExposure);
  const [pdfchartdata, pdfchartoptions] = usePDFChartOptions(
    sectorExposure,
    onImageSectorExposure
  );
  const textData = useTranslate("RiskAssessement.portfolio.overview.sectorExposure");
  const curr = useSelector((state) => state.currency);

  return (
    <div className={style.container}>
      <div id="tooltip-sector" className={style.tooltip}>
        <span className={classNames("title", style.title)}></span>
        <div className={style.desc}>
          <span className={classNames("value", style.value)}></span>
          <span className={classNames(style["risk-indic"], "risk-indic")}>
            <span className={classNames(style.indicator, "indicator")}></span>
            <span className={classNames(style.risk, "risk")}></span>
          </span>
        </div>
      </div>
      <CardRisk
        title={textData.title}
        desc={textData.desc.replace("EUR/USD", curr.toUpperCase())}
        className={style["sector-exposure"]}
      >
        <div style={{ height: "300px" }}>
          {data && <Doughnut data={data} options={options} />}
        </div>
      </CardRisk>
      <div
        style={{
          width: "530px",
          position: "absolute",
          top: "-9999px",
          zIndex: 999,
          background: "#fff",
        }}
      >
        {data && <Doughnut data={pdfchartdata} options={pdfchartoptions} />}
      </div>
    </div>
  );
};

export default SectorExposure;
