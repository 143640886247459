import { Box, Container, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import style from "./style.module.css";
import classNames from "classnames";

const FormStep = ({ title, desc, levels, index, selection, onCardClick }) => {
  return (
    <Stack maxWidth={"xl"}>
      <Typography variant="h3" className={style.title}>
        Step {index}:&nbsp;{title}
      </Typography>
      <Typography className={style.desc}>{desc}</Typography>
      <Typography className={classNames(style.desc, style["sub-desc"])}>
        For each element, select the level that applies to your FI.
      </Typography>

      <Container maxWidth="lg" className={style.grid}>
        <Container className={style.header}>
          <Typography>Level 0 (Not aligned)</Typography>
          <Typography>Level 1 (In progress)</Typography>
          <Typography>Level 2 (Aligned)</Typography>
        </Container>
        {levels.map((level, i) => {
          return (
            <Fragment key={i}>
              <Box className={style.description}>
                <Typography variant="h4">{level.title}</Typography>
                <Typography>{level.desc}</Typography>
              </Box>
              {level.choices.map((choice, k) => (
                <Box
                  key={k}
                  className={[
                    style.card,
                    selection
                      ? selection[i] == k
                        ? style.selected
                        : style.unselected
                      : style.unselected,
                  ]}
                  onClick={() => {
                    onCardClick(index, i, k);
                  }}
                >
                  <Typography>{choice}</Typography>
                </Box>
              ))}
            </Fragment>
          );
        })}
      </Container>
    </Stack>
  );
};

export default FormStep;
