import { createSlice } from "@reduxjs/toolkit";

const initialState = "usd";
const currency = createSlice({
  name: "currency",
  initialState,
  reducers: {
    switchCurrency: (state) => {
      if (state == "usd") return "eur";
      else return "usd";
    },
  },
});

export default currency.reducer;
export const { switchCurrency } = currency.actions;
