import { Container } from "@mui/material";
import style from "./style.module.css";
import React from "react";
const Hero = ({ children, className, maxWidth, containerClassName }) => {
  return (
    <Container maxWidth={false} className={[style.container, className]}>
      <Container
        className={containerClassName}
        maxWidth={maxWidth != undefined ? maxWidth : "lg"}
      >
        {children}
      </Container>
    </Container>
  );
};

export default Hero;
