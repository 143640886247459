import { AccordionPrimary } from "Components/Accordion";
import style from "./style.module.css";
import React from "react";
import Empty from "Components/Empty";

const Third = ({ onChange, expanded, detail }) => {
  return (
    <AccordionPrimary
      title={"Corporate governance risk management guidance"}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      {detail.length == 0 ? (
        <Empty message={"No Suggestions"} className={style.empty} />
      ) : (
        <ul className={style.card}>
          {detail.map((e, i) => {
            return <li key={i}>{e}</li>;
          })}
        </ul>
      )}
    </AccordionPrimary>
  );
};

export default Third;
