const formatNumber = (value) => {
  const numberValue = value.replace(/,/g, "");
  const parts = numberValue.split(".");
  const wholeNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedValue =
    parts.length > 1 ? `${wholeNumber}.${parts[1]}` : wholeNumber;
  return formattedValue;
};

const transformFormdata = (data) => {
  const tdata = data.map((e, i) => {
    return {
      title: e.sector,
      editable: e.subSectors.length == 1,
      ...(e.subSectors.length != 1 && {
        subsectors: e.subSectors.map((t, j) => {
          return { title: t };
        }),
      }),
    };
  });
  return tdata;
};

const compareArray = (array1, array2) => {
  return (
    array1.length === array2?.length &&
    array1.every((value, index) => value === array2[index])
  );
};

function formatNumberAsCurrency(curr, number) {
  return `${curr.toUpperCase()} ${formatNumber(number.toString())}`;
}

function convertFormattedStringToNumber(str) {
  const numericString = str.replace(/,/g, "");
  const number = parseFloat(numericString);
  return number;
}

function getYear() {
  return new Date().getFullYear();
}

export {
  formatNumber,
  convertFormattedStringToNumber,
  transformFormdata,
  formatNumberAsCurrency,
  compareArray,
  getYear,
};
