import { Container, Typography } from "@mui/material";
import Hero from "Components/Hero";
import Icon from "Components/Icon";
import AccordionContents from "./AccordionContents";
import { BorderBotton } from "Components/StyledButton";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import React from "react";

const GrievanceMechanism = () => {
  const { t } = useTranslation();
  return (
    <>
      <Hero
        className={style.hero}
        maxWidth={false}
        containerClassName={style.herocontainer}
      >
        <Typography className={style.title}>{t("EGM.hero.title")} </Typography>
        <div style={{ marginBottom: "37px" }}>
          {t("EGM.hero.desc", { returnObjects: true }).map((e, i) => {
            return (
              <Typography className={style.desc} key={i}>
                {e}
              </Typography>
            );
          })}
        </div>
        <a
          target="_blank"
          href="/221101_FMO Guidance Note Grievance Mechanisms_External.pdf"
        >
          <BorderBotton
            className={style.fmo}
            startIcon={
              <Icon icon="download" color={"green"} width={16} height={16} />
            }
          >
            {t("EGM.hero.dwnld")}
          </BorderBotton>
        </a>
      </Hero>
      <Container maxWidth={"lg"} className={style.accordion}>
        <AccordionContents />
      </Container>
    </>
  );
};

export default GrievanceMechanism;
