import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

const cx = classNames.bind(style);

const Table = ({ data, config }) => {
  return (
    <>
      {config.showHeader && (
        <Row className={config.headerClass}>
          {config.columns.map((e, i) => {
            return (
              <CellContainer key={i} span={e.span}>
                <Cell className={e.headerClass}>{e.title}</Cell>
              </CellContainer>
            );
          })}
        </Row>
      )}
      {data?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <Row key={i} className={classNames(config.rowClass)}>
              {config.columns.map((m, j) => {
                return (
                  <CellContainer
                    key={j}
                    span={m.span}
                    onClick={() => m.onClick && m.onClick(e, e[m.dataindex], i)}
                  >
                    <Cell
                      key={j}
                      className={
                        typeof m.className == "function"
                          ? m.className(e, e[m.dataindex], i)
                          : m.className
                      }
                      span={m.span}
                    >
                      {m.render ? m.render(e, e[m.dataindex], i) : e[m.dataindex]}
                    </Cell>{" "}
                  </CellContainer>
                );
              })}
            </Row>

            {config.subColumns?.map((m, j) => {
              return (
                e[m.dataindex] && (
                  <div
                    key={j}
                    className={classNames(style["sub-row-container"], {
                      [style["sub-row-container-collapsed"]]:
                        config.expandedRow != i,
                    })}
                  >
                    {e[m.dataindex]?.map((b, k) => {
                      return (
                        <Row
                          className={cx(style["sub-row-default"], m.rowClass)}
                          key={k}
                        >
                          {m.columns.map((c, r) => {
                            return (
                              <CellContainer
                                key={r}
                                span={config.columns[r].span || c.span}
                              >
                                <Cell
                                  span={config.columns[r].span || c.span}
                                  className={c.className}
                                >
                                  {c.render
                                    ? c.render(b, b[c.dataindex], i, k)
                                    : b[c.dataindex]}
                                </Cell>
                              </CellContainer>
                            );
                          })}
                        </Row>
                      );
                    })}
                  </div>
                )
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

const CellContainer = ({ children, className, span, onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      style={{ flexBasis: span + "%" }}
      className={cx(style["cell-container-default"], "cell-container", className)}
    >
      {children}
    </div>
  );
};

const Row = ({ children, className }) => {
  return <div className={cx(style["row-default"], className)}>{children}</div>;
};

const Cell = ({ children, className }) => {
  return <div className={cx(style["cell-default"], className)}>{children}</div>;
};

export default Table;
export { Row, Cell, CellContainer };
