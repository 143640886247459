import style from "./index.module.css";
import { t } from "i18next";
import { AccordionPrimary } from "Components/Accordion";
import Card from "Components/Card";
import { Typography } from "@mui/material";
import React from "react";

const Second = ({ onChange, expanded }) => {
  const trans = t("EGM.accordion", { returnObjects: true })[1];

  return (
    <AccordionPrimary
      title={trans.title}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      <Typography className={style.p0}>{trans.desc}</Typography>
      <Typography className={style.t0} variant="h2">
        {trans.principle}
      </Typography>
      <div className={style.container}>
        {trans.cards.map((e, i) => {
          return (
            <Card
              key={i}
              title={e.title}
              content={e.content}
              className={style["custom-card"]}
            />
          );
        })}
      </div>
    </AccordionPrimary>
  );
};

export default Second;
