import style from "./index.module.css";
import { t } from "i18next";

import { AccordionPrimary } from "Components/Accordion";
import { Typography } from "@mui/material";
import React from "react";

const First = ({ onChange, expanded }) => {
  const trans = t("EGM.accordion", { returnObjects: true })[0];

  return (
    <AccordionPrimary
      title={trans.title}
      onChange={onChange}
      expanded={expanded}
      contentClassName={style.content}
    >
      <div className={style.container}>
        <div className={style.s0}>
          <img src="/Images/discussion.svg" />
        </div>
        <div className={style.s1}>
          <Typography className={style.p0}>{trans.paragraphs[0]}</Typography>
          <Typography>
            <b>{trans.paragraphs[1]}</b>
            {trans.paragraphs[2]}
          </Typography>
        </div>
        <div className={style.s2}>
          <Typography>
            <b>{trans.paragraphs[3]}</b>
            {trans.paragraphs[4]}
          </Typography>
        </div>
        <div className={style.s3}>
          <img src="/Images/lightBulb.svg" />
          <div className={style["tip-content"]}>
            <Typography variant="h2">{trans.tip.title}</Typography>
            <ul>
              {trans.tip.desc.map((e, i) => (
                <li key={i}>
                  <Typography>
                    <span className={style.med}>{e.pre}</span>
                    {e.desc}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </AccordionPrimary>
  );
};

export default First;
