import { Typography, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";
import style from "./style.module.css";
import classNames from "classnames";

const AssessmentNav = () => {
  return (
    <Stack className={style.nav} direction={"row"} gap={"37px"}>
      <NavLink
        to="PortfolioAssessment"
        className={({ isActive }) =>
          classNames([style.link, { [style["ative-link"]]: isActive }])
        }
      >
        <Typography>Portfolio Assessment</Typography>
      </NavLink>
      <NavLink
        to="TransactionAssessment"
        className={({ isActive }) =>
          classNames([style.link, { [style["ative-link"]]: isActive }])
        }
      >
        <Typography>Transaction Assessment</Typography>
      </NavLink>
    </Stack>
  );
};

export default AssessmentNav;
