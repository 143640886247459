import React, { useState } from "react";
import style from "./style.module.css";
import Table from "Components/CustomTable";
import SingleRow from "Components/SingleRow";
import classNames from "classnames";
import useSectorTableConfig from "./tableConfig";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "Components/StyledButton";
import useCalculation from "Hooks/calculation";
import { useValidateSectExpo } from "Hooks/validatePortfolio";
import useTranslate from "Hooks/translate";
import { formatNumber, transformFormdata } from "Utils/functions";

const fillAll =
  "Please make sure to fill all the text fields. Enter 0 for fields where data is not available and make sure there are no errors in any field(highlighted in red).";
const sumZero =
  "The financed volume cannot be 0, please enter your financed volume per sector before clicking the ‘Next’ button";
const Sectors = ({ nexStep, formdata, formfielddata, visible }) => {
  const [sectortableconfig] = useSectorTableConfig(
    formdata,
    formfielddata.sectorList,
    visible
  );
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(fillAll);
  const { sum } = useCalculation(formdata);
  const valid = useValidateSectExpo(
    formdata,
    transformFormdata(formfielddata.sectorList)
  );
  const textdata = useTranslate("RiskAssessement.portfolio.newPortfolio");

  return (
    <>
      <Table
        data={transformFormdata(formfielddata.sectorList)}
        config={sectortableconfig}
      />
      <SingleRow
        data={[
          {
            title: "title",
            value: textdata.total,
            span: 45,
            className: style["result-title"],
          },
          {
            title: "total",
            value: Number.isNaN(sum) ? "-" : formatNumber(sum.toString()),
            span: 27.5,
            className: style["result-total"],
          },
          { span: 27.5 },
        ]}
        className={classNames(style.result, style["result-row"])}
      />

      <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
        {error && (!valid || sum == 0) && (
          <Typography className={style.error}>{errMsg}</Typography>
        )}
        <PrimaryButton
          onClick={() => {
            if (sum == 0) {
              setError(true);
              setErrMsg(sumZero);
              return;
            }

            if (!valid) {
              setError(true);
              setErrMsg(fillAll);
            } else {
              setError(false);
              nexStep();
            }
          }}
        >
          {textdata.next}
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default Sectors;
