import { Button } from "@mui/material";
import style from "./style.module.css";
import React from "react";
import classNames from "classnames";

const sx = classNames.bind(style);

const BorderBotton = (props) => (
  <Button {...props} className={sx(style.bordered, props.className)}></Button>
);

const PrimaryButton = (props) => (
  <Button {...props} className={sx(style.primary, props.className)}></Button>
);

const SecondaryButton = (props) => (
  <Button {...props} className={sx(style.secondary, props.className)}></Button>
);

const IconButton = (props) => (
  <Button {...props} className={sx(props.className, style["icon-button"])}></Button>
);

export { BorderBotton, SecondaryButton, IconButton, PrimaryButton };
