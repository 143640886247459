import { createSlice } from "@reduxjs/toolkit";

const EXPIRY_TIME = 86400000 * 1;

const initialState = {
  portfolioRefreshAt: Date.now() + EXPIRY_TIME * 31,
  transactionRefreshAt: Date.now() + EXPIRY_TIME * 31,
  unprbRefreshAt: 0,
};

const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    setPortfolioRefreshFromNow: (state) => {
      return { ...state, portfolioRefreshAt: Date.now() + EXPIRY_TIME };
    },
    setTransactionRefreshFromNow: (state) => {
      return { ...state, transactionRefreshAt: Date.now() + EXPIRY_TIME };
    },
    setUnprbRefreshFromNow: (state) => {
      return { ...state, unprbRefreshAt: Date.now() + EXPIRY_TIME };
    },
  },
});

export default refreshSlice.reducer;
export const {
  setPortfolioRefreshFromNow,
  setTransactionRefreshFromNow,
  setUnprbRefreshFromNow,
} = refreshSlice.actions;
