import { Container, Stack, Typography } from "@mui/material";
import Hero from "Components/Hero";
import React from "react";
import style from "./style.module.css";
import Portfoliocard from "Components/PortfolioCard/index.js";
import useTranslate from "Hooks/translate";

const Home = () => {
  const textdata = useTranslate("RiskAssessement.home");
  return (
    <>
      <Hero
        className={style.hero}
        maxWidth={false}
        containerClassName={style.herocontainer}
      >
        <Typography variant="h1" className={style.title}>
          {textdata.title}
        </Typography>
        <Typography className={style.desc}>{textdata.desc}</Typography>
      </Hero>
      <Container maxWidth={"lg"}>
        <Stack flexDirection={"row"} gap={"41px"} className={style.container}>
          {textdata.cards.map((e, i) => (
            <Portfoliocard {...e} key={i} />
          ))}
        </Stack>
      </Container>
    </>
  );
};

export default Home;
