import Icon from "Components/Icon";
import React from "react";
import style from "./style.module.css";

const ItemContext = ({ title, popover }) => {
  return (
    <div className={style.container}>
      <span className={style.title}>{title}</span>
      <span className={style.info}>
        <Icon icon={"inf"} />
        <div className={style.popover}>
          <h3 className={style["popover-title"]}>{popover.title}</h3>
          <p className={style["popover-desc"]}>{popover.desc}</p>
          <p className={style["popover-sub-desc"]}>{popover.subdesc}</p>
          <p className={style["popover-source"]}>
            Source:{" "}
            <a href={popover.source.link} target="_blank" rel="noreferrer">
              {popover.source.text}
            </a>
          </p>
        </div>
      </span>
    </div>
  );
};

export default ItemContext;
