import { Stack, Typography } from "@mui/material";
import Progress from "Components/Progress";
import React from "react";
import style from "./style.module.css";
import Icon from "Components/Icon";
import { allKeys } from "Utils/consts";

const OverviewStep = ({ step, index, suggestions }) => {
  return (
    <Stack className={style["step-container"]} gap={"50px"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h2" className={style.title}>
          {`Step ${index}: ${step.title}`}
        </Typography>
        <Progress progress={suggestions.progress} className={style.progress} />
      </Stack>

      <Stack gap={"30px"}>
        <Stack direction={"row"} gap={"20px"}>
          <Typography className={style.option}>Alignment Status</Typography>
          <Typography className={style.option}>
            What can you do to improve?
          </Typography>
        </Stack>
        {step.levels.map((e, i) => {
          const suggestion =
            suggestions.dimensionValues[allKeys[index][i]].suggestion;
          const userInput = suggestions.dimensionValues[allKeys[index][i]].userInput;
          return (
            <Stack direction={"row"} gap={"20px"} key={i}>
              <Typography className={style.option}>
                <Icon
                  icon={userInput <= 1 ? "close" : "checkmark"}
                  className={style.icon}
                />
                {e.title}
              </Typography>
              <Typography className={style.suggestion}>{suggestion}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default OverviewStep;
