import {
  Document,
  Page,
  Image,
  View,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import { formatNumberAsCurrency } from "Utils/functions";

import interitalic from "Assets/fonts/Inter-Italic.otf";
import interbold from "Assets/fonts/Inter-Bold.otf";
import interreg from "Assets/fonts/Inter-Regular.ttf";
import intersembold from "Assets/fonts/Inter-SemiBold.ttf";
import PDFFooter from "Components/PDFFooter";
import PDFHeader from "Components/PDFHeader";
import PDFGuidelines from "Components/PDFGuidelines";

Font.register({
  family: "InterBold",
  format: "truetype",
  src: interbold,
  fontWeight: "700",
});

Font.register({
  family: "Inter",
  format: "truetype",
  fontStyle: "italic",
  src: interitalic,
});

Font.register({
  family: "Inter",
  format: "truetype",
  src: interreg,
  fontWeight: "400",
});

Font.register({
  family: "InterSemBold",
  format: "truetype",
  src: intersembold,
  fontWeight: "600",
});

Font.register({
  family: "InterLevel",
  format: "truetype",
  src: interreg,
});

Font.register({
  family: "Inter",
  format: "truetype",
  src: interreg,
  fontWeight: "400",
});

const iconMap = {
  "occupational health and safety": "occupational",
  "hazardous materials": "hazard",
  "water use and wastewater": "water",
  "air pollution": "airPollution",
  "impacts to community": "impact",
  "biodiversity and habitat": "biodiversity",
  "labor standards": "labor",
  "child and forced labor": "child",
  "energy use": "energy",
  "waste management": "waste",
  "exposure to disease / disease prevention": "biodiversity",
  "animal welfare": "welfare",
};

const icons = {
  impact: "/Images/IC_ImpactsToCommunity.png",
  hazard: "/Images/IC_HazardousMaterial.png",
  labor: "/Images/IC_LaborStandard.png",
  biodiversity: "/Images/IC_BiodiversityAndHabitate.png",
  child: "/Images/IC_ChildAndForcedLabor.png",
  airPollution: "/Images/IC_AirPollution.png",
  water: "/Images/IC_WaterUseAndWasteWater.png",
  occupational: "/Images/IC_OccupationalHealthAndSafety.png",
  default: "/Images/default.png",
  energy: "/Images/IC_EnergyConsumption.png",
  waste: "/Images/IC_WasteManagement.png",
  welfare: "/Images/IC_AnimalWelfare.png",
};

const Card = ({ title, children, style }) => {
  return (
    <View
      style={{
        width: "100%",
        borderRadius: "3pt",
        paddingVertical: "10pt",
        ...style,
      }}
    >
      <Text
        style={{
          fontFamily: "InterSemBold",
          color: "#192E54",
          fontSize: "12pt",
          paddingHorizontal: "10pt",
          marginBottom: "10pt",
          fontWeight: 600,
        }}
      >
        {title}
      </Text>
      <View
        style={{
          marginHorizontal: "10pt",
          height: "1pt",
          backgroundColor: "#000000",
        }}
      ></View>
      <View style={{ paddingHorizontal: "10pt" }}>{children}</View>
    </View>
  );
};

const TransactionData = ({ curr, keytransaction, sectorList, countryList }) => {
  const style = StyleSheet.create({
    info: {
      width: "45%",
      flexDirection: "row",
      gap: "20pt",
      color: "#192E54",
      fontSize: "10pt",
      fontFamily: "Inter",
    },
    container: {
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      rowGap: "5pt",
      marginTop: "20pt",
    },
    title: {
      flexBasis: "50%",
      fontFamily: "InterSemBold",
    },
    val: {
      flexBasis: "50%",
    },
  });
  return (
    <View style={style.container}>
      <View style={style.info}>
        <Text style={style.title}>Loan size</Text>
        <Text style={style.val}>
          {formatNumberAsCurrency(curr, keytransaction.loansize)}
        </Text>
      </View>
      <View style={style.info}>
        <Text style={style.title}>Total assets borrowed</Text>
        <Text style={style.val}>
          {formatNumberAsCurrency(curr, keytransaction.totalasset)}
        </Text>
      </View>
      <View style={style.info}>
        <Text style={style.title}>Sector</Text>
        <Text style={style.val}>{sectorList[keytransaction.sector].title}</Text>
      </View>
      <View style={style.info}>
        <Text style={style.title}>Sub sector</Text>
        <Text style={style.val}>
          {(sectorList[keytransaction.sector].subsectors &&
            sectorList[keytransaction.sector].subsectors[keytransaction.subsector]
              ?.title) ||
            sectorList[keytransaction.sector].title}
        </Text>
      </View>
      <View style={style.info}>
        <Text style={style.title}>Country</Text>
        <Text style={style.val}>{countryList[keytransaction.country]}</Text>
      </View>
    </View>
  );
};

const RiskTicket = ({ title, level, keyRisks }) => {
  const levels = {
    High: 2,
    Medium: 1,
    Low: 0,
  };
  const style = StyleSheet.create({
    container: {
      padding: "6pt 16pt 9pt 8pt",
      backgroundColor: "#EBECED",
      borderRadius: "3pt",
      marginTop: "10pt",
    },
    title: {
      fontWeight: 600,
      fontSize: "10px",
      fontFamily: "InterSemBold",
      color: "#192e54",
      marginBottom: "8pt",
    },
    level: {
      fontSize: "8pt",
      fontFamily: "Inter",
      color: "#388098",
    },
    levelIndicator: {
      height: "4pt",
      borderRadius: "20pt",
      flex: 1,
      backgroundColor: "#fff",
    },
    High: {
      backgroundColor: "#ed6e85",
    },
    Low: {
      backgroundColor: "#94e1bc",
    },
    Medium: {
      backgroundColor: "#f9d09e",
    },
    "main-risks": {
      flexDirection: "row",
      gap: "20pt",
      marginLeft: "auto",
      alignItems: "flex-start",
    },
  });
  return (
    <View style={style.container}>
      <View>
        <Text style={style.title}>{title}</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text
              style={{
                color: "#192E54",
                fontFamily: "InterLevel",
                fontSize: "10pt",
                fontWeight: "700",
              }}
            >
              Risk Level: {level}
            </Text>
          </View>
          <View style={{ flexDirection: "row", gap: "5pt", flexBasis: "145pt" }}>
            {Array.from({ length: 3 }, (e, i) => {
              return (
                <View
                  style={[
                    style.levelIndicator,
                    i <= levels[level] ? style[level] : {},
                  ]}
                  key={i}
                ></View>
              );
            })}
          </View>
        </View>
      </View>
      {keyRisks && (
        <View style={{ flexDirection: "row", marginTop: "8pt" }}>
          <Text
            style={[
              style.level,
              {
                color: "#192E54",
                fontFamily: "InterLevel",
                fontSize: "8pt",
                fontWeight: "bold",
              },
            ]}
          >
            Main Risk:
          </Text>
          <View style={style["main-risks"]}>
            {keyRisks.map((e, i) => {
              return (
                <View key={i} style={{ flexBasis: "68pt", alignItems: "center" }}>
                  <Image
                    src={icons[iconMap[e.type.toLowerCase()]] || "default"}
                    key={i}
                    style={{ width: "20pt", height: "20pt" }}
                  />
                  <Text
                    style={{
                      fontFamily: "InterLevel",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      color: "#192E54",
                      lineHeight: 1.8,
                      opacity: 0.7,
                      textAlign: "center",
                      marginTop: "5pt",
                    }}
                  >
                    {e.type}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

const ContextualRisk = ({ contextualRisk }) => {
  return (
    <View
      style={{
        width: "100%",
        borderRadius: "3pt",
        backgroundColor: "#F4F5F6",
        padding: "11pt 20pt 11pt 8pt",
        marginTop: "10pt",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text
        style={{ fontFamily: "InterSemBold", color: "#192E54", fontSize: "12pt" }}
      >
        Contextual risk(s):
      </Text>

      <View style={{ flexDirection: "row", gap: "17pt" }}>
        {contextualRisk.map((e, i) => {
          return (
            <Text
              style={{ fontFamily: "Inter", fontSize: "10pt", color: "#192E54" }}
              key={i}
            >
              {e.label}
            </Text>
          );
        })}
      </View>
    </View>
  );
};

const MyDoc = ({ details, sectorList, countryList, curr, img }) => {
  const { keytransaction } = details.userData;
  const { ESGRiskProfile } = details.resultData.detail;
  const { contextualRisk } = details.resultData.detail;
  const { companyName } = details.resultData.overview;
  const { corporateGovernanceRiskManagementGuidance } = details.resultData.detail;
  const { keyRisks, otherRisks } = details.resultData.detail.commonMaterialESIssues;
  const style = StyleSheet.create({
    label: {
      fontSize: "10pt",
      color: "#fff",
    },
    sectionTitle: {
      fontFamily: "InterSemBold",
      fontSize: "12pt",
      color: "#192e54",
      marginBottom: "10pt",
    },
    mainTitle: {
      fontFamily: "InterBold",
      fontSize: "14pt",
      color: "#192E54",
      marginBottom: "10pt",
      marginTop: "4pt",
      paddingHorizontal: "10pt",
    },
  });
  return (
    <Document>
      <Page style={{ padding: "60pt 30pt" }}>
        <PDFHeader />
        <Text style={style.mainTitle}>Transaction Assessment - {companyName}</Text>
        <View style={{ gap: "15pt" }}>
          <Card title={"Transaction Data"}>
            <TransactionData
              countryList={countryList}
              keytransaction={keytransaction}
              sectorList={sectorList}
              curr={curr}
            />
          </Card>
          <Card title={"ESG risk profile"}>
            <RiskTicket
              title={"Environmental & Social risk"}
              level={ESGRiskProfile.socialRiskLevel}
              keyRisks={ESGRiskProfile.keyRisks}
            />
            <RiskTicket
              title={"Corporate governance risk"}
              level={ESGRiskProfile.governanceRiskLevel}
            />
            <ContextualRisk contextualRisk={contextualRisk} />
          </Card>
          <Card title={"Guidelines  on ESG risk management"}>
            <Text
              style={{
                fontFamily: "InterSemBold",
                fontSize: "10pt",
                color: "#192E54",
                marginTop: "10pt",
                marginBottom: "17pt",
              }}
            >
              Suggested E&S risk management approach
            </Text>
            {img && <Image source={img} style={{ marginLeft: "20px" }} />}
          </Card>
        </View>
        {corporateGovernanceRiskManagementGuidance.length != 0 && (
          <>
            <Text style={style.mainTitle} break>
              Transaction Assessment - {companyName}
            </Text>
            <Card title={"Corporate governance risk management guidance"}>
              <View
                style={{
                  gap: "12pt",
                  paddingTop: "10pt",
                  paddingLeft: "20pt",
                  paddingRight: "35pt",
                }}
              >
                {corporateGovernanceRiskManagementGuidance.map((e, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      gap: "5pt",
                      fontFamily: "Inter",
                      fontSize: "10pt",
                      fontWeight: 100,
                      color: "#192E54",
                      opacity: 0.75,
                    }}
                  >
                    <Text>�&nbsp;</Text>
                    <Text>{e}</Text>
                  </View>
                ))}
              </View>
            </Card>
          </>
        )}
        <View break>
          <Text style={[style.mainTitle, { paddingHorizontal: "0pt" }]}>
            Sector guidelines: {sectorList[keytransaction.sector].title}
          </Text>
          <Text
            style={{
              fontFamily: "InterBold",
              fontSize: "12pt",
              color: "#388098",
            }}
          >
            Key E&S issues in portfolio
          </Text>
          <PDFGuidelines
            keyRisks={keyRisks}
            otherRisks={otherRisks}
            title={sectorList[keytransaction.sector].title}
          />
        </View>
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default MyDoc;
