import CustomInput from "Components/Input";
import { formatNumber } from "Utils/functions";
import classNames from "classnames/bind";
import React, { useRef } from "react";
import style from "./style.module.css";
import { formattedfloatRegex } from "Utils/consts";

const cx = classNames.bind(style);

function FormattedInput({ onChange, className, inputValue = "", placeholder }) {
  const prevval = useRef("");

  const handleChange = (event) => {
    const { value } = event.target;
    prevval.current = inputValue;
    if (value.length < inputValue.length) {
      onChange(formatNumber(value));
      return;
    }
    onChange(formatNumber(value), prevval.current);
  };

  return (
    <CustomInput
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
      className={cx(
        className,
        {
          valid: formattedfloatRegex.test(inputValue),
        },
        {
          error: !formattedfloatRegex.test(inputValue),
        },
        {
          empty: inputValue.length == 0,
        }
      )}
    />
  );
}

export default FormattedInput;
