import api from "Api";
import { useState } from "react";
import usePortfolioDataTransform from "./portfolioDataTransform";
import { GENERATE_PORTFOLIO_RESULT } from "Api/endpoints";

const usePortfolioResult = (dummydatasect) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [reqdata] = usePortfolioDataTransform(dummydatasect);

  const request = () => {
    setLoading(true);
    api
      .post(GENERATE_PORTFOLIO_RESULT, reqdata)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };
  return [request, loading, data, error];
};

export default usePortfolioResult;
