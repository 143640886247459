import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { IconButton, PrimaryButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import style from "./style.module.css";
import OverviewStep from "Components/OverviewStep";
import { useTranslation } from "react-i18next";
import useSuggestions from "Hooks/suggestions";
import { Link } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "./Doc";

const Overview = () => {
  const { t } = useTranslation();
  const Steps = t("UNPRB.steps", { returnObjects: true });
  const [loading, suggestions] = useSuggestions();
  return (
    <>
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={style.header}
        >
          <Typography variant="h2" className={style.title}>
            UNPRB self-assessment results overview
          </Typography>
          <Stack direction={"row"} gap={"14px"}>
            <Link className={style.link} to={"/UNPRB/form"}>
              <PrimaryButton className={style.update}>
                Update self-assessment
              </PrimaryButton>
            </Link>
            {!suggestions.length == 0 && (
              <PDFDownloadLink
                document={<MyDoc suggestions={suggestions} steps={Steps} />}
                fileName="unprb.pdf"
              >
                {({ blob, url, load, error }) =>
                  load ? (
                    <CircularProgress />
                  ) : (
                    <IconButton className={style.download}>
                      <Icon icon={"download"} />
                    </IconButton>
                  )
                }
              </PDFDownloadLink>
            )}
          </Stack>
        </Stack>

        {loading ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          <Stack gap={"70px"}>
            {Steps.map((e, i) => (
              <OverviewStep
                key={i}
                step={e}
                index={i}
                suggestions={suggestions[i]}
              />
            ))}
          </Stack>
        )}
      </Container>
    </>
  );
};

export default Overview;
