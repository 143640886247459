import { createSlice } from "@reduxjs/toolkit";

const initialState = { exp: {}, expNperm: {}, expPerm: {} };

const tempportfoliodata = createSlice({
  name: "portfoliodata",
  initialState,
  reducers: {
    setExp: (state, action) => {
      const { ind, subind, val } = action.payload;
      const res = {
        ...state.exp,
        [ind]:
          subind != undefined
            ? {
                ...state.exp[ind],
                subsectors: { ...state.exp[ind]?.subsectors, [subind]: val },
              }
            : { value: val },
      };
      return { ...state, exp: res };
    },
    setNPermExp: (state, action) => {
      return { ...state, expNperm: { ...state.expNperm, ...action.payload } };
    },
    setPermExp: (state, action) => {
      return { ...state, expPerm: { ...state.expPerm, ...action.payload } };
    },
    setTempData: (state, action) => {
      return action.payload;
    },
    clearTempData: (state, action) => {
      const formdata = action.payload;
      const alt = {};
      for (const key in formdata.sectorList) {
        let subsectors = {};

        if (formdata.sectorList[key].subSectors.length != 1) {
          subsectors.subsectors = {};
          formdata.sectorList[key].subSectors.forEach((element, i) => {
            subsectors.subsectors[i] = "0";
          });
        } else {
          subsectors = { value: "0" };
        }
        alt[key] = subsectors;
      }
      return { ...initialState, exp: alt };
    },
  },
});

export default tempportfoliodata.reducer;
export const { setExp, setNPermExp, setPermExp, clearTempData, setTempData } =
  tempportfoliodata.actions;
