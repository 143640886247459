import { useSelector } from "react-redux";

const dummydataNperm = [
  "forcedOrChildLabour",
  "activitiesOrMaterials",
  "crossBorderTrade",
  "destructionOfHighConservationAreas",
  "radioactive",
  "pornography",
  "racist",
];

const dummydataperm = ["alcohol", "tobacco", "weapons"];

function unformatString(str) {
  return str.replace(/,/g, "");
}

const usePortfolioDataTransform = (dummydatasect) => {
  const portfoliodata = useSelector((state) => {
    return state.tempportfoliodata;
  });

  const expuserdata = portfoliodata.exp;
  const Npermuserdata = portfoliodata.expNperm;
  const permuserdata = portfoliodata.expPerm;

  const reqbody = { step1Data: {}, step2Data: {}, step3Data: {} };

  let reqexpobj = {};
  for (const key in expuserdata) {
    let subob = {};
    if (expuserdata[key]?.subsectors) {
      for (const subkey in expuserdata[key].subsectors) {
        subob = {
          ...subob,
          [dummydatasect[key].subsectors[subkey].title]: unformatString(
            expuserdata[key].subsectors[subkey]
          ),
        };
      }
    } else if (expuserdata[key].value) {
      subob = { [dummydatasect[key].title]: unformatString(expuserdata[key].value) };
    }
    reqexpobj = { ...reqexpobj, [dummydatasect[key].title]: subob };
  }
  reqbody.step1Data = reqexpobj;

  let reqnpermdata = {};
  for (const key in Npermuserdata) {
    reqnpermdata = {
      ...reqnpermdata,
      [dummydataNperm[key]]: Npermuserdata[key] == "yes" ? "1" : "0",
    };
  }
  reqbody.step2Data = reqnpermdata;

  let reqpermdata = {};
  for (const key in permuserdata) {
    reqpermdata = { ...reqpermdata, [dummydataperm[key]]: permuserdata[key] };
  }
  reqbody.step3Data = reqpermdata;
  return [reqbody];
};

export default usePortfolioDataTransform;
