import { Document, Page, View, StyleSheet, Text, Font } from "@react-pdf/renderer";
import React from "react";
import interitalic from "Assets/fonts/Inter-Italic.otf";
import interbold from "Assets/fonts/Inter-Bold.otf";
import interreg from "Assets/fonts/Inter-Regular.ttf";
import intersembold from "Assets/fonts/Inter-SemiBold.ttf";
import PDFFooter from "Components/PDFFooter";
import PDFHeader from "Components/PDFHeader";

Font.register({
  family: "InterBold",
  format: "truetype",
  src: interbold,
  fontWeight: "700",
});

Font.register({
  family: "Inter",
  format: "truetype",
  fontStyle: "italic",
  src: interitalic,
});

Font.register({
  family: "Inter",
  format: "truetype",
  src: interreg,
  fontWeight: "400",
});

Font.register({
  family: "InterSemBold",
  format: "truetype",
  src: intersembold,
  fontWeight: "600",
});

const Transaction = ({ info }) => {
  const style = StyleSheet.create({
    container: {
      height: "167pt",
      fontFamily: "Inter",
      fontSize: "12pt",
      justifyContent: "center",
      color: "#192E54",
      padding: "8pt 8pt 19pt 22pt",
    },
    cell: {
      flexDirection: "row",
    },
    levelIndicator: {
      width: "9pt",
      height: "9pt",
      borderRadius: "3pt",
      backgroundColor: "",
    },
    riskLevel: {
      flexDirection: "row",
      alignItems: "center",
      gap: "5pt",
    },
    riskLabel: {
      minWidth: "250pt",
    },
  });

  const riskLevel = {
    High: "#F01F1F",
    Medium: "#F0DD7D",
    Low: "#84D39A",
  };

  return (
    <View style={style.container}>
      <View
        style={[
          style.cell,
          {
            fontSize: "12pt",
            borderBottom: "1pt solid #000",
            paddingBottom: "5pt",
            marginBottom: "10pt",
          },
        ]}
      >
        <Text style={{ fontFamily: "InterBold" }}>Transaction name : </Text>
        <Text style={{ fontFamily: "InterBold" }}>{info.companyName}</Text>
      </View>
      <View style={{ gap: "20pt", fontSize: "10pt" }}>
        <View style={style.cell}>
          <Text>Subsector: </Text>
          <Text>{info.subSector}</Text>
        </View>
        <View style={style.cell}>
          <Text style={style.riskLabel}>Environmental & Social (E&S) Risk: </Text>
          <View style={style.riskLevel}>
            <View
              style={[
                style.levelIndicator,
                {
                  backgroundColor: riskLevel[info.socialRiskLevel],
                },
              ]}
            ></View>
            <Text>{info.socialRiskLevel}</Text>
          </View>
        </View>
        <View style={style.cell}>
          <Text style={style.riskLabel}>Corporate Governance Risk: </Text>
          <View style={style.riskLevel}>
            <View
              style={[
                style.levelIndicator,
                {
                  backgroundColor: riskLevel[info.governanceRiskLevel],
                },
              ]}
            ></View>
            <Text>{info.governanceRiskLevel}</Text>
          </View>
        </View>
        <View style={style.cell}>
          <Text>Suggested E&S Risk management approach: </Text>
          <Text>{info.suggestedESRiskManagementApproach}</Text>
        </View>
      </View>
    </View>
  );
};

const MyDoc = ({ details }) => {
  const style = StyleSheet.create({
    tableWrapper: {
      gap: "22pt",
    },
  });
  return (
    <Document>
      <Page style={{ padding: "64pt 39pt" }}>
        <PDFHeader />
        <Text
          style={{
            fontFamily: "InterBold",
            fontSize: "16pt",
            color: "#192E54",
            marginBottom: "15pt",
          }}
        >
          Transaction Assessment - Overview
        </Text>
        <View style={style.tableWrapper}>
          {details.resultData.map((e, i) => {
            return <Transaction info={e.overview} key={i} />;
          })}
        </View>
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default MyDoc;
