import Modal from "Components/Modal";
import React, { useState } from "react";
import KeyTransactionData from "./KeyTransactionData";
import CorporateTransactionData from "./CorporateTransactionData";
import style from "./style.module.css";
import useTranslate from "Hooks/translate";
import { useDispatch, useSelector } from "react-redux";
import { saveTransaction } from "reducers/transactionsData";
import useFormField from "Hooks/formFieldsData";
import { CircularProgress } from "@mui/material";
import { setTransactionRefreshFromNow } from "reducers/refreshData";

const NewTransaction = ({ visible, onClose, edit, ind, onCreate }) => {
  const textdata = useTranslate(
    "RiskAssessement.transactions.newTransaction.stepsTitle"
  );
  const userData = useSelector((state) => state.temptransactiondata);
  const [activeStep, setActiveStep] = useState(0);

  const [formfields, loading] = useFormField();

  const dispatch = useDispatch();

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleCreate(resultdata) {
    setActiveStep(0);
    dispatch(saveTransaction({ resultdata, userData }));
    dispatch(setTransactionRefreshFromNow());
    onCreate();
    onClose();
  }

  function handleSave(resultdata) {
    setActiveStep(0);
    dispatch(saveTransaction({ resultdata, userData, index: ind }));
    onClose();
  }

  function handlePrev() {
    setActiveStep(activeStep - 1);
  }

  const stepsData = [
    {
      body: loading ? (
        <CircularProgress />
      ) : (
        <KeyTransactionData key={0} onNext={handleNext} formfields={formfields} />
      ),
      title: textdata[0],
    },
    {
      body: (
        <CorporateTransactionData
          key={1}
          onCreate={handleCreate}
          onPrev={handlePrev}
          formfields={formfields}
          edit={edit}
          onSave={handleSave}
        />
      ),
      title: textdata[1],
    },
  ];

  return (
    <>
      <Modal
        visible={visible}
        title={"New transaction"}
        onClose={onClose}
        subtitle={`Step (${activeStep + 1}/${stepsData.length}) - ${
          stepsData[activeStep].title
        }`}
        className={style.modal}
      >
        {stepsData[activeStep].body}
      </Modal>
    </>
  );
};

export default NewTransaction;
