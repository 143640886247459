import React from "react";
import style from "./style.module.css";
import { Typography } from "@mui/material";
import classNames from "classnames";

const CardRisk = ({ title, subtitle, desc, className, children }) => {
  return (
    <div className={classNames(style.container, className)}>
      {title && <Typography className={style.title}>{title}</Typography>}
      {desc && <Typography className={style.desc}>{desc}</Typography>}
      {subtitle && <Typography className={style.subtitle}>{subtitle}</Typography>}
      {children}
    </div>
  );
};

export default CardRisk;
