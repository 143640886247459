import { Stack, Typography } from "@mui/material";
import CardList from "Components/CardList";
import { PrimaryButton, SecondaryButton } from "Components/StyledButton";
import useTranslate from "Hooks/translate";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setNPermExp } from "reducers/tempportfoliodata";
import style from "./style.module.css";
import { useValidateExpNPerm } from "Hooks/validatePortfolio";

const errMsg =
  "Please respond to each question by selecting ‘Yes’ or ‘No’ before clicking the ‘Next’ button";

const ExposureNonPerm = ({ nexStep, prevStep, formdata }) => {
  const data = useTranslate("RiskAssessement.portfolio.expNPerm");
  const dispatch = useDispatch();
  const valid = useValidateExpNPerm(formdata);
  const textData = useTranslate("RiskAssessement.portfolio.newPortfolio");
  const [error, setError] = useState(false);

  return (
    <>
      <Stack direction={"column"} gap={"10px"} className={style.contents}>
        {data.map((e, i) => {
          return (
            <CardList
              key={i}
              desc={e}
              onChange={(val) => {
                setError(false);
                dispatch(setNPermExp({ [i]: val }));
              }}
              value={formdata[i]}
            />
          );
        })}
      </Stack>
      {error && !valid && (
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
          <Typography className={style.error}>{errMsg}</Typography>
        </Stack>
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className={style.controls}
      >
        <SecondaryButton onClick={prevStep}>{textData.prev}</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (!valid) {
              setError(true);
            } else {
              setError(false);
              nexStep();
            }
          }}
        >
          {textData.next}
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default ExposureNonPerm;
