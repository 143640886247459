const GENERATE_UNPRB_RESULT = "generate_result_unprb";
const GENERATE_FORM_DATA = "get_data_for_transaction_form";
const GENERATE_TRANSACTION_RESULT = "generate_result_transaction";
const GENERATE_PORTFOLIO_RESULT = "generate_result_portfolio";
export {
  GENERATE_UNPRB_RESULT,
  GENERATE_FORM_DATA,
  GENERATE_TRANSACTION_RESULT,
  GENERATE_PORTFOLIO_RESULT,
};
