import { convertFormattedStringToNumber } from "Utils/functions";
import { useSelector } from "react-redux";

const useTransactionDataTransform = (formfields) => {
  const sectorList = formfields.sectorList;
  const countryList = formfields.countryList;
  const userInputs = useSelector((state) => state.temptransactiondata);
  const { loansize, totalasset, company, sector, subsector, country } =
    userInputs.keytransaction;
  const { corporate } = userInputs;

  const trans = {
    keyData: {
      companyName: company,
      loanSize: convertFormattedStringToNumber(loansize).toString(),
      totalAssetsBorrower: convertFormattedStringToNumber(totalasset).toString(),
      sector: sectorList[sector].sector,
      subSector: sectorList[sector].subSectors[subsector],
      country: countryList[country],
    },
    corporateGovernanceData: {
      "Commitment to Corporate Governance": corporate[0] == "no" ? 0 : 1,
      "Decision-making and Strategic Oversight": corporate[1] == "no" ? 0 : 1,
      "Control Environment and Processes": corporate[2] == "no" ? 0 : 1,
      "Transparency and Disclosure": corporate[3] == "no" ? 0 : 1,
      Ownership: corporate[4] == "no" ? 0 : 1,
    },
  };

  return trans;
};

export default useTransactionDataTransform;
