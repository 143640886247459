/* eslint-disable no-unused-vars */
import { CircularProgress, Container } from "@mui/material";
import ControlHeader from "Components/ControlHeader";
import React, { useState } from "react";
import style from "./style.module.css";
import CardContainer from "Components/CardContainer";
import ItemTransaction from "Components/ItemTransaction";
import useTranslate from "Hooks/translate";
import { formatNumberAsCurrency } from "Utils/functions";
import ItemContext from "Components/ItemContext";
import ItemRisk from "Components/ItemRisk";
import AccordionContents from "./AccordionContents";
import { useSelector } from "react-redux";
import { IconButton } from "Components/StyledButton";
import Icon from "Components/Icon";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "./pdfdoc";
import Empty from "Components/Empty";

const TransactionDetails = ({ resultData, userData }) => {
  const { keytransaction } = userData;
  const { detail } = resultData;
  const curr = useSelector((state) => state.currency);
  const [img, setImg] = useState();

  const sectorList = useTranslate("RiskAssessement.portfolio.sectors");
  const countryList = useTranslate("RiskAssessement.countrylist");
  const textdata = useTranslate("RiskAssessement.transactions.overview.detail");
  return (
    <Container maxWidth={false} className={style.container}>
      <ControlHeader
        title={resultData.overview.companyName}
        download
        downloadElem={
          <PDFDownloadLink
            document={
              <MyDoc
                details={{ resultData, userData }}
                countryList={countryList}
                sectorList={sectorList}
                curr={curr}
                img={img}
                companyName={""}
              />
            }
            fileName="Transaction_overview.pdf"
          >
            {({ blob, url, loading, error }) => (
              <IconButton>
                {loading ? (
                  <CircularProgress className={style.loader} size={"32px"} />
                ) : (
                  <Icon icon="download" />
                )}
              </IconButton>
            )}
          </PDFDownloadLink>
        }
      />
      <Container maxWidth="false" className={style.details}>
        <CardContainer title={textdata.transactioncard.title}>
          <div className={style["items-wrapper"]}>
            <ItemTransaction
              title={textdata.transactioncard.loansize}
              desc={formatNumberAsCurrency(curr, keytransaction.loansize)}
            />
            <ItemTransaction
              title={textdata.transactioncard.totasset}
              desc={formatNumberAsCurrency(curr, keytransaction.totalasset)}
            />
            <ItemTransaction
              title={textdata.transactioncard.sector}
              desc={sectorList[keytransaction.sector].title}
            />
            <ItemTransaction
              title={textdata.transactioncard.subsector}
              desc={
                (sectorList[keytransaction.sector].subsectors &&
                  sectorList[keytransaction.sector].subsectors[
                    keytransaction.subsector
                  ]?.title) ||
                sectorList[keytransaction.sector].title
              }
            />
            <ItemTransaction
              title={textdata.transactioncard.country}
              desc={countryList[keytransaction.country]}
            />
          </div>
        </CardContainer>

        <CardContainer title={textdata.esgcard.title}>
          <div className={style["risk-profile"]}>
            <ItemRisk
              title={textdata.esgcard.eandsrisk}
              level={detail.ESGRiskProfile.socialRiskLevel}
              keyRisks={detail.ESGRiskProfile.keyRisks}
            />
            <ItemRisk
              title={textdata.esgcard.corprisk}
              level={detail.ESGRiskProfile.governanceRiskLevel}
            />
          </div>
        </CardContainer>
        <CardContainer title={textdata.contextual}>
          <div className={style["contextual-risk"]}>
            {detail.contextualRisk.length ? (
              detail.contextualRisk.map((e, i) => (
                <ItemContext
                  title={e.label}
                  popover={{
                    title: e.label,
                    desc: e.definition,
                    subdesc: e.indicators,
                    source: e.source,
                  }}
                  key={i}
                />
              ))
            ) : (
              <Empty
                message={"No contextual risks identified for this transaction"}
                className={style.empty}
              />
            )}
          </div>
        </CardContainer>
      </Container>
      <AccordionContents
        detail={detail}
        onImageLoaded={(img) => {
          const s = new XMLSerializer().serializeToString(img);
          const canv = document.createElement("canvas");
          canv.width = 1113;
          canv.height = 383;
          const altimg = new Image(1113, 383);
          altimg.onload = function () {
            canv.getContext("2d").drawImage(this, 0, 0);
            setImg(canv.toDataURL());
          };
          altimg.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(s);
        }}
      />
    </Container>
  );
};

export default TransactionDetails;
