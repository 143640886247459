import { PrimaryButton } from "Components/StyledButton";
import React from "react";
import style from "./style.module.css";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setKeyTransaction } from "reducers/temptransactiondata";
import FormattedGeneralInput from "Components/FormattedGeneralInput";
import { formattedfloatRegex } from "Utils/consts";

const KeyTransactionData = ({ onNext, formfields }) => {
  const sectorsdata = formfields.sectorList;

  const formdata = useSelector((state) => state.temptransactiondata.keytransaction);
  const dispatch = useDispatch();
  const curr = useSelector((state) => state.currency);

  const formvalidation = {
    loansize: formdata.loansize.match(formattedfloatRegex),
    totalasset: formdata.totalasset.match(formattedfloatRegex),
    company: formdata.company.length > 2,
    confirm: formdata.confirm,
  };

  const errors = {
    loansize: !formvalidation.loansize && formdata.loansize != "",
    totalasset: !formvalidation.totalasset && formdata.totalasset != "",
    company: !formvalidation.company && formdata.company != "",
    confirm: !formvalidation.confirm && formdata.confirm != "",
  };

  return (
    <>
      <div className={style.form}>
        <FormControl className={style.control}>
          <FormLabel>Company name</FormLabel>
          <TextField
            className={style.textfield}
            value={formdata.company}
            error={errors.company}
            label={errors.company ? "Error" : null}
            helperText={errors.company && "Incorrect entry"}
            onChange={(e) =>
              dispatch(setKeyTransaction({ company: e.target.value }))
            }
          />
        </FormControl>
        <FormControl className={style.control}>
          <FormLabel>Loan size ({curr.toUpperCase()})</FormLabel>
          <FormattedGeneralInput
            inputValue={formdata.loansize}
            error={errors.loansize}
            helper={errors.loansize && "Incorrect entry"}
            label={errors.loansize ? "Error" : null}
            onChange={(e) => {
              dispatch(setKeyTransaction({ loansize: e }));
            }}
          />
        </FormControl>
        <FormControl className={style.control}>
          <FormLabel>Total assets borrower ({curr.toUpperCase()})</FormLabel>
          <FormattedGeneralInput
            style={style.inp}
            inputValue={formdata.totalasset}
            error={errors.totalasset}
            label={errors.totalasset ? "Error" : null}
            helper={errors.totalasset && "Incorrect entry"}
            onChange={(e) => {
              dispatch(setKeyTransaction({ totalasset: e }));
            }}
          />
        </FormControl>
        <FormControl className={style.control}>
          <FormLabel>Sector</FormLabel>
          <Select
            className={style.select}
            onChange={(e) => {
              dispatch(setKeyTransaction({ sector: e.target.value }));
            }}
            value={formdata.sector}
            fullWidth
          >
            {sectorsdata.map((e, i) => (
              <MenuItem value={i} key={i}>
                {e.sector}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={style.control}>
          <FormLabel>Subsector</FormLabel>
          <Select
            className={style.select}
            value={formdata.subsector}
            disabled={!sectorsdata[formdata.sector].subSectors}
            onChange={(e) => {
              dispatch(setKeyTransaction({ subsector: e.target.value }));
            }}
          >
            {sectorsdata[formdata.sector]?.subSectors?.map((e, i) => (
              <MenuItem value={i} key={i}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={style.control}>
          <FormLabel>Country</FormLabel>
          <Select
            className={style.select}
            value={formdata.country}
            onChange={(e) => {
              dispatch(setKeyTransaction({ country: e.target.value }));
            }}
          >
            {formfields.countryList.map((e, i) => (
              <MenuItem value={i} key={i}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControlLabel
        className={style.confirm}
        label={[
          "I confirm that the transaction is not in the prohibited activities of the ",
          <a
            key={1}
            href="https://www.fmo.nl/policies-and-position-statements"
            target="_blank"
            className={style.link}
            rel="noreferrer"
          >
            Exclusion List
          </a>,
          " and is within the 10% portfolio limit of permitted Exclusion List activities",
        ]}
        checked={formdata.confirm}
        control={
          <Checkbox
            className={style.checkbox}
            onChange={(e) => {
              dispatch(setKeyTransaction({ confirm: e.target.checked }));
            }}
          />
        }
      ></FormControlLabel>

      <Stack alignItems={"center"}>
        <PrimaryButton
          className={style.next}
          disabled={
            !(
              formvalidation.loansize &&
              formvalidation.confirm &&
              formvalidation.totalasset &&
              formvalidation.company
            )
          }
          onClick={onNext}
        >
          Next
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default KeyTransactionData;
