import Table from "Components/CustomTable";
import useTranslate from "Hooks/translate";
import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.css";
import SingleRow from "Components/SingleRow";
import { PrimaryButton, SecondaryButton } from "Components/StyledButton";
import { CircularProgress, Stack, Typography } from "@mui/material";
import usePortfolioResult from "Hooks/portfolioResults";
import { useValidateExpPerm } from "Hooks/validatePortfolio";
import useExpPermConf from "./expPermConf";

const errMsg =
  "Please enter a % to indicate the exposure to Permitted Exclusion List Items or enter ‘0’ when there is no exposure to Permitted Exclusion List Items";

const ExposurePerm = ({
  prevStep,
  onCreatePortfolio,
  formdata,
  editMode,
  dummydatasect,
}) => {
  const data = useTranslate("RiskAssessement.portfolio.expPerm");
  const [validErr, setValidErr] = useState(false);
  const [requestportfoliodata, loading, result, error] =
    usePortfolioResult(dummydatasect);
  const valid = useValidateExpPerm(formdata);
  const textdata = useTranslate("RiskAssessement.portfolio.newPortfolio");

  const sum = useMemo(() => {
    return Object.values(formdata).reduce(
      (acc, curr) => acc + parseFloat(curr || 0),
      0
    );
  }, [formdata]);

  useEffect(() => {
    if (!loading && result && !error) {
      onCreatePortfolio(result);
    }
  }, [loading, result, error]);

  const conf = useExpPermConf(formdata, setValidErr);

  return (
    <>
      <Table config={conf} data={data} />
      <SingleRow
        data={[
          {
            span: 70,
            title: "title",
            value: textdata.overall,
            className: style["result-title"],
          },
          {
            value: (Number.isNaN(sum) ? "-" : sum) + "%",
            span: 30,
            className: style["result-total"],
          },
        ]}
        className={style.result}
      />
      {validErr && (
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
          <Typography className={style.error}>{errMsg}</Typography>
        </Stack>
      )}
      <Stack direction={"row"} justifyContent={"space-between"}>
        <SecondaryButton onClick={prevStep}>{textdata.prev}</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (!valid || loading) {
              setValidErr(true);
            } else {
              setValidErr(false);
              requestportfoliodata();
            }
          }}
        >
          {loading ? (
            <CircularProgress size={"19px"} />
          ) : editMode ? (
            textdata.save
          ) : (
            textdata.create
          )}
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default ExposurePerm;
