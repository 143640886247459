import CardList from "Components/CardList";
import useTranslate from "Hooks/translate";
import React, { useEffect } from "react";
import style from "./style.module.css";
import { CircularProgress, Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "Components/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import { setCorporate } from "reducers/temptransactiondata";
import useTransactionResult from "Hooks/transactionResult";

const CorporateTransactionData = ({
  onPrev,
  onCreate,
  formfields,
  edit,
  ind,
  onSave,
}) => {
  const textdata = useTranslate("RiskAssessement.transactions.corporateData");
  const formdata = useSelector((state) => state.temptransactiondata.corporate);
  const dispatch = useDispatch();

  const [request, loading, data, error] = useTransactionResult(formfields);

  useEffect(() => {
    if (!loading && data && !error) {
      edit ? onSave(data, ind) : onCreate(data);
    }
  }, [loading, data, error]);

  return (
    <>
      <div className={style.container}>
        {textdata.map((e, i) => {
          return (
            <CardList
              key={i}
              value={formdata[i]}
              onChange={(val) => dispatch(setCorporate({ [i]: val }))}
              desc={
                <>
                  <h3 className={style.title}>{e.title}</h3>
                  <p className={style.desc}>{e.desc}</p>
                </>
              }
            />
          );
        })}
      </div>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className={style.buttons}
      >
        <SecondaryButton onClick={onPrev}>Previous</SecondaryButton>
        <PrimaryButton
          onClick={request}
          disabled={Object.keys(formdata).length < textdata.length || loading}
        >
          {loading ? (
            <CircularProgress className={style.progress} size={"24px"} />
          ) : edit ? (
            "Save Transaction"
          ) : (
            "Create Transaction"
          )}
        </PrimaryButton>
      </Stack>
    </>
  );
};

export default CorporateTransactionData;
