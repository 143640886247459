import React from "react";
import style from "./style.module.css";

const Logo = () => {
  return (
    <div className={style.container}>
      <img src="/Images/logo_fmo.png" />
      <div>
        <img src="/Images/FI_Logo.png" />
        <div></div>
      </div>
    </div>
  );
};

export default Logo;
